import React, { useEffect, useState } from 'react'
import styles from './AllRecentProjects.module.css'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import ProductsHeader from '../ProductsHeader/ProductsHeader'
import { Link, useNavigate } from 'react-router-dom'
import { TbCurrencyDram } from 'react-icons/tb'
import ProductsFooter from '../ProductsFooter/ProductsFooter'
import { useSelector } from 'react-redux'

export default function AllRecentProjects() {
  const navigate = useNavigate()
  const [recentProjects, setRecentProjects] = useState([])
  const [webpSupported, setWebpSupported] = useState(false)
  const lang = useSelector((state) => state.lang.lang.toLowerCase())

  const fetchRecentProjects = async () => {
    try {
      const { data } = await axios.get(
        'https://elwood-2e61a87e55cb.herokuapp.com/recentProjects'
      )
      const langLowerCase = lang.toLowerCase()

      const parsedDatas =
        data &&
        data
          .filter((item) => +item.disabled === 0)
          .map((item) => {
            const images = item[`images_${langLowerCase}`]
              ? JSON.parse(item[`images_${langLowerCase}`])
              : []

            const properties = item[`properties_${langLowerCase}`]
              ? JSON.parse(item[`properties_${langLowerCase}`])
              : []

            const title = item[`title_${langLowerCase}`]
              ? item[`title_${langLowerCase}`]
              : ''

            return {
              ...item,
              images,
              title,
              properties,
            }
          })
      setRecentProjects(parsedDatas)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchRecentProjects()
  }, [])

  useEffect(() => {
    fetchRecentProjects()
  }, [lang])

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  const { t, i18n } = useTranslation()

  useEffect(() => {
    const canvas = document.createElement('canvas')
    if (canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0) {
      setWebpSupported(true)
    } else {
      setWebpSupported(false)
    }
  }, [])

  function handleNavigateToAllProductPage(id) {
    navigate(`/recentProjects/project/${id}`)
  }

  return (
    <>
      <ProductsHeader />
      <section className={styles.section}>
        <h1 className={styles.allRecentProjectsTitle}>
          {t('allRecentProjects')}
        </h1>
        <div className={styles.container}>
          <div className={styles.productsContainer}>
            {recentProjects &&
              recentProjects.map((project, index) => (
                <div
                  key={project.id}
                  className={styles.product}
                  onClick={() => handleNavigateToAllProductPage(project.id)}>
                  <div className={styles.imgContainer}>
                    {' '}
                    <img
                      src={
                        webpSupported
                          ? project?.images[0]?.webp
                          : project?.images[0]?.jpeg
                      }
                      alt={project?.title}
                      className={styles.productImg}
                    />
                  </div>
                  <h2 className={styles.productTitle}>{project?.title}</h2>
                  <p className={styles.productPrice}>{project?.date}</p>
                </div>
              ))}
          </div>
        </div>
      </section>
      <ProductsFooter />
    </>
  )
}
