import styles from './AdminBanners.module.css'
import { AiOutlineCloudUpload, AiOutlineClose } from 'react-icons/ai'
import Modal from './AddBanner/Modal/Modal'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import { v4 as uuid } from 'uuid'
import storage from '../../firebase'
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import SendIcon from '@mui/icons-material/Send'
import imageCompression from 'browser-image-compression'
import Loader from '../../components/Loader/Loader'
import { Modal as AntDModal } from 'antd'
import DataTable from 'react-data-table-component'
import { styled } from '@mui/system'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'

const StyledIconButton = styled(IconButton)({
  '&:hover': {
    color: 'red',
  },
})

export default function AdminBanners() {
  const [baners, setBaners] = useState([])
  const [showModalAdd, setShowModalAdd] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)
  const [image, setImage] = useState(null)
  const [loadingSend, setLoadingSend] = useState(false)
  const [loading, setLoading] = useState(true)
  const [img, setImg] = useState(null)
  const [webpSupported, setWebpSupported] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [itemId, setItemId] = useState(null)

  const columns = [
    {
      name: 'id',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Banner Img',
      selector: (row) => (
        <div className={styles.tableProductColumn}>
          <img
            style={{ height: '40px', margin: '10px', borderRadius: '10px' }}
            src={row?.img?.jpeg}
            alt="Image"
          />
        </div>
      ),
    },
    // {
    //   name: 'Status',
    //   selector: (row) => (
    //     <div className={styles.statusCont}>
    //       <StyledIconButton
    //         onClick={() => {
    //           handleChangeStatus(row.id, +row.status === 1 ? 0 : 1)
    //           setTimeout(() => {
    //             window.location.reload()
    //           }, 1500)
    //         }}
    //         aria-label="delete">
    //         <Home color={row.status == 1 ? 'primary' : 'default'} />
    //       </StyledIconButton>
    //     </div>
    //   ),
    // },

    // {
    //   name: 'Disabled',
    //   selector: (row) => (
    //     <div>
    //       {+row.disabled ? (
    //         <Switch
    //           {...label}
    //           defaultChecked
    //           onChange={handleSwitchChange(row.id)}
    //         />
    //       ) : (
    //         <Switch {...label} onChange={handleSwitchChange(row.id)} />
    //       )}
    //     </div>
    //   ),
    // },
    {
      name: 'Actions',
      selector: (row) => (
        <Stack direction="row" spacing={1}>
          <StyledIconButton aria-label="delete">
            <DeleteIcon
              color="error"
              onClick={(e) => {
                showModal()
                setItemId({ itemId: row.id, img: row.img })
              }}
            />
          </StyledIconButton>
        </Stack>
      ),
    },
  ]

  console.log('BANERS')
  console.log(baners)

  const onInputChange = (e) => {
    setImage(e.target.files[0])
  }

  const showModal = () => {
    setDeleteOpen(true)
  }

  const hideModal = () => {
    setDeleteOpen(false)
  }

  async function onSubmit(e) {
    e.preventDefault()

    if (image === null) return
    const newName = image.name + uuid()

    try {
      const compressedImage = await imageCompression(image, {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 800,
      })

      const jpegImageRef = ref(storage, `images/baners/${newName}.jpeg`)
      await uploadBytes(jpegImageRef, compressedImage).then(() =>
        console.log('Success !')
      )

      const webpImage = await createImageBitmap(image)
      const webpCanvas = document.createElement('canvas')
      webpCanvas.width = webpImage.width
      webpCanvas.height = webpImage.height
      const webpCanvasContext = webpCanvas.getContext('2d')
      webpCanvasContext.drawImage(webpImage, 0, 0)

      const webpBlob = await new Promise((resolve) => {
        webpCanvas.toBlob((blob) => resolve(blob), 'image/webp', 0.9)
      })

      if (webpBlob) {
        const webpImageRef = ref(storage, `images/baners/${newName}.webp`)
        await uploadBytes(webpImageRef, webpBlob).then(() =>
          toast.success('Image uploaded success !')
        )

        const jpegDownloadURL = await getDownloadURL(jpegImageRef)
        const webpDownloadURL = await getDownloadURL(webpImageRef)
        const imgObj = { jpeg: jpegDownloadURL, webp: webpDownloadURL }

        setImg(imgObj)
        try {
          setLoadingSend(true)
          await axios
            .post('https://elwood-2e61a87e55cb.herokuapp.com/addbaner', {
              img: JSON.stringify(imgObj),
            })
            .then(() => {
              setShowModalAdd(false)
            })
            .then(() => {
              setLoadingSend(false)
              window.location.reload()
            })
        } catch (error) {
          toast.error(error.message)
        }
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const handleShowModalAdd = () => {
    setShowModalAdd(true)
  }

  const handleCloseModal = () => {
    setShowModalAdd(false)
  }

  useEffect(() => {
    setLoading(true)
    const fetchBanners = async () => {
      try {
        const res = await axios.get(
          'https://elwood-2e61a87e55cb.herokuapp.com/baners'
        )
        const datasParser =
          res.data &&
          res.data.map((item) => {
            return {
              id: item.id,
              img: JSON.parse(item.img),
            }
          })

        setBaners(datasParser)
        setLoading(false)
      } catch (error) {
        toast.error(error.message)
        setLoading(false)
      }
    }
    fetchBanners()
  }, [])

  async function handleDeleteBaner(info) {
    const imageRefJpeg = ref(storage, info.img.jpeg)
    const imageRefWebp = ref(storage, info.img.webp)

    deleteObject(imageRefJpeg).then(() =>
      console.log('Jpeg has been delete sucess')
    )
    deleteObject(imageRefWebp).then(() =>
      console.log('Webp has been elete success')
    )
    try {
      await axios
        .delete(
          `https://elwood-2e61a87e55cb.herokuapp.com/deletebaner/${info.itemId}`
        )
        .then(() => window.location.reload())
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    const canvas = document.createElement('canvas')
    if (canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0) {
      setWebpSupported(true)
    } else {
      setWebpSupported(false)
    }
  }, [])

  return (
    <>
      <DataTable
        title="Banner List"
        columns={columns}
        data={baners}
        fixedHeader
        fixedHeaderScrollHeight="450px"
        pagination
        selectableRowsHighlight
        highlightOnHover
        style={{ width: '1800px' }}
        selectableRowsNoSelectAll
        subHeaderWrap
        customStyles={{
          tableWrapper: {
            overflowX: 'auto',
          },
        }}
        actions={
          <div>
            <Stack spacing={2} direction="row">
              <Button onClick={handleShowModalAdd} variant="outlined">
                Create Banner
              </Button>
            </Stack>
          </div>
        }
      />
      <Modal show={showModalAdd} type="add" handleClose={handleCloseModal}>
        <div className={styles.container}>
          <input
            type="file"
            id="file"
            accept="image/*"
            hidden
            onChange={onInputChange}
          />
          <div
            className={`${styles.imgArea} ${
              selectedImage ? styles.active : ''
            }`}
            data-img="">
            {selectedImage ? (
              <div>
                <img
                  src={webpSupported ? img?.webp : img?.jpeg}
                  alt="Selected"
                />
                <AiOutlineClose className={styles.deleteImg} />
              </div>
            ) : (
              <>
                <AiOutlineCloudUpload />
                <h3>Upload Image</h3>
                <p>
                  Image size must be less than <span>2MB</span>
                </p>
              </>
            )}
          </div>
          <button
            className={styles.selectImage}
            onClick={() => document.querySelector('#file').click()}>
            Select Image
          </button>
          <div className={styles.btnContainer}>
            <Stack direction="row" spacing={2}>
              <Button onClick={handleCloseModal} variant="outlixaned">
                Cancel
              </Button>
              <LoadingButton
                endIcon={<SendIcon />}
                onClick={onSubmit}
                loading={loadingSend}
                variant="contained">
                <span>Send</span>
              </LoadingButton>
            </Stack>
          </div>
        </div>
      </Modal>
      <ToastContainer />
      <AntDModal
        title="You are sure delete category"
        open={deleteOpen}
        onOk={() => {
          handleDeleteBaner(itemId)
          hideModal()
        }}
        onCancel={hideModal}
        okText="Delete"
        cancelText="Cancel"></AntDModal>
      {loading && <Loader />}
    </>
  )
}
