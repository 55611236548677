import React, { useEffect, useState } from 'react'
import styles from './AdminWebSiteStatus.module.css'
import { Switch } from 'antd'
import axios from 'axios'

export default function AdminWebSiteStatus() {
  const [status, setStatus] = useState('')

  useEffect(() => {
    const fetchWebsiteStatus = async () => {
      try {
        const { data } = await axios.get(
          'https://elwood-2e61a87e55cb.herokuapp.com/status'
        )
        setStatus(data[0].status)
      } catch (error) {
        console.log(error)
      }
    }
    fetchWebsiteStatus()
  }, [])

  useEffect(() => {
    const editStatus = async () => {
      try {
        await axios.put(
          'https://elwood-2e61a87e55cb.herokuapp.com/edit/websiteStatus',
          {
            status: status,
          }
        )
      } catch (error) {
        console.log(error)
      }
    }
    editStatus()
  }, [status])

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h2 className={styles.title}>Website Status Mode</h2>

        <div className={styles.switchsContainer}>
          <div className={styles.switch}>
            <label className={styles.label}>Offline Mode:</label>
            <Switch
              checked={status === 'offline' && true}
              onClick={() => setStatus('offline')}
            />
          </div>
          <div className={styles.switch}>
            <label className={styles.label}>Online Mode:</label>
            <Switch
              checked={status === 'online' && true}
              onClick={() => setStatus('online')}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
