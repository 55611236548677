import { Routes, Route } from 'react-router-dom'
import Admin from './pages/Admin/Admin'
import MainWrapper from './pages/MainWrapper/MainWrapper'
import AdminDashboard from './pages/AdminDashboard/AdminDashboard'
import { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import FailedAdmin from './pages/FailedAdmin/FailedAdmin'
import AlreadyLogined from './pages/AlreadyLogined/AlreadyLogined'
import AddProductPage from './pages/AddProductPage/AddProductPage'
import AdminProductsDataTables from './components/AdminProductsDataTables/AdminProductsDataTables'
import AdminColors from './components/AdminColors/AdminColors'
import AdminMaterials from './components/AdminMaterials/AdminMaterials'
import AdminBanners from './pages/AdminBanners/AdminBanners'
import AdminCategories from './components/AdminCategories/AdminCategories'
import AllProducts from './pages/AllProducts/AllProducts'
import NotFound from './pages/NotFound/NotFound'
import ProductCard from './pages/ProductPage/ProductPage'
import AdminAbout from './pages/AdminAbout/AdminAbout'
import AddAboutAm from './pages/AdminAbout/AddAbout/AddAboutAm'
import AddAboutRu from './pages/AdminAbout/AddAbout/AddAboutRu'
import AddAboutEn from './pages/AdminAbout/AddAbout/AddAboutEn'
import EditProductPage from './pages/EditProductPage/EditProductPage'
import AdminInfoAccordeon from './pages/AdminInfoAccordeon/AdminInfoAccordeon'
import AdminContact from './pages/AdminContact/AdminContact'
import MainPagePresentation from './pages/MainPagePresentation/MainPagePresentation'
import AdminPartners from './pages/AdminPartners/AdminPartners'
import EditAbout from './pages/EditAboutUs/EditAboutUs'
import AdminWebSiteStatus from './pages/AdminWebSiteStatus/AdminWebSiteStatus'
import MainInformation from './pages/MainInformation/MainInformation'
import Cookies from 'js-cookie'
import AdminRecentProjects from './pages/AdminRecentProjects/AdminRecentProjects'
import AllRecentProjects from './components/AllRecentProjects/AllRecentProjects'
import RecentProjectPage from './components/RecentProjectPage/RecentProjectPage'
import styles from './OfflinePage.module.css'

export default function App() {
  const [status, setStatus] = useState('')
  const [isAdmin, setIsAdmin] = useState(null)
  const [clientsCount, setClintsCount] = useState(0)
  const [isAdminCookie, setIsAdminCookie] = useState(
    Cookies.get('isAdmin') || null
  )

  useEffect(() => {
    const fetchIsAdmin = async () => {
      try {
        const res = await axios.get(
          'https://elwood-2e61a87e55cb.herokuapp.com/getIsAdmin'
        )
        setIsAdmin(res.data[0]?.isAdmin)
      } catch (error) {
        console.log(error)
      }
    }
    fetchIsAdmin()
  }, [])
  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const { data } = await axios.get(
          'https://elwood-2e61a87e55cb.herokuapp.com/status'
        )
        data && setStatus(data[0]?.status)
      } catch (error) {
        console.log(error.message)
      }
    }
    fetchStatus()
  }, [])

  if (status == 'offline' && isAdmin == 1 && isAdminCookie == 'true') {
    return (
      <div>
        <Routes>
          <Route path="/" element={<MainWrapper />} />
          <Route path="/allProducts" element={<AllProducts />} />
          <Route path="/recentsProjects" element={<AllRecentProjects />} />
          <Route path="/allProducts/product/:id" element={<ProductCard />} />
          <Route
            path="/recentProjects/project/:id"
            element={<RecentProjectPage />}
          />
          <Route path="*" element={<NotFound />} />
          <Route
            path="/admin"
            element={isAdmin && isAdmin ? <AlreadyLogined /> : <Admin />}
          />

          <Route
            path="/admin/admindashboard"
            element={isAdmin && isAdmin ? <AdminDashboard /> : <FailedAdmin />}>
            <Route
              path="/admin/admindashboard/aboutus/addaboutpage/am"
              element={isAdmin && isAdmin ? <AddAboutAm /> : <FailedAdmin />}
            />
            <Route
              path="/admin/admindashboard/aboutus/addaboutpage/ru"
              element={isAdmin && isAdmin ? <AddAboutRu /> : <FailedAdmin />}
            />
            <Route
              path="/admin/admindashboard/aboutus/addaboutpage/en"
              element={isAdmin && isAdmin ? <AddAboutEn /> : <FailedAdmin />}
            />
            <Route
              path="/admin/admindashboard/addproduct"
              element={
                isAdmin && isAdmin ? <AddProductPage /> : <FailedAdmin />
              }
            />
            <Route
              path="/admin/admindashboard/products"
              element={
                isAdmin && isAdmin ? (
                  <AdminProductsDataTables />
                ) : (
                  <FailedAdmin />
                )
              }
            />
            <Route
              path="/admin/admindashboard/recentProjects"
              element={
                isAdmin && isAdmin ? <AdminRecentProjects /> : <FailedAdmin />
              }
            />
            <Route
              path="/admin/admindashboard/websiteStatus"
              element={
                isAdmin && isAdmin ? <AdminWebSiteStatus /> : <FailedAdmin />
              }
            />
            <Route
              path="/admin/admindashboard/main"
              element={
                isAdmin && isAdmin ? <MainPagePresentation /> : <FailedAdmin />
              }
            />
            <Route
              path="/admin/admindashboard/editProduct/:id"
              element={
                isAdmin && isAdmin ? <EditProductPage /> : <FailedAdmin />
              }
            />
            <Route
              path="/admin/admindashboard/infoAccordeon"
              element={
                isAdmin && isAdmin ? <AdminInfoAccordeon /> : <FailedAdmin />
              }
            />
            <Route
              path="/admin/admindashboard/productsColor"
              element={isAdmin && isAdmin ? <AdminColors /> : <FailedAdmin />}
            />
            <Route
              path="/admin/admindashboard/productsMaterials"
              element={
                isAdmin && isAdmin ? <AdminMaterials /> : <FailedAdmin />
              }
            />
            <Route
              path="/admin/admindashboard/partners"
              element={isAdmin && isAdmin ? <AdminPartners /> : <FailedAdmin />}
            />
            <Route
              path="/admin/admindashboard/mainBaners"
              element={isAdmin && isAdmin ? <AdminBanners /> : <FailedAdmin />}
            />
            <Route
              path="/admin/admindashboard/categories"
              element={
                isAdmin && isAdmin ? <AdminCategories /> : <FailedAdmin />
              }
            />
            <Route
              path="/admin/admindashboard/categories"
              element={isAdmin && isAdmin ? <AdminAbout /> : <FailedAdmin />}
            />
            <Route
              path="/admin/admindashboard/aboutUs"
              element={isAdmin && isAdmin ? <AdminAbout /> : <FailedAdmin />}
            />
            <Route
              path="/admin/admindashboard/mainInformation"
              element={
                isAdmin && isAdmin ? <MainInformation /> : <FailedAdmin />
              }
            />
            <Route
              path="/admin/admindashboard/about/editAbout/:id"
              element={isAdmin && isAdmin ? <EditAbout /> : <FailedAdmin />}
            />
            <Route
              path="/admin/admindashboard/contacts"
              element={isAdmin && isAdmin ? <AdminContact /> : <FailedAdmin />}
            />
          </Route>
        </Routes>
      </div>
    )
  } else if (status == 'offline') {
    return (
      <div>
        <Routes>
          <Route
            path="/"
            element={
              <div className={styles.container}>
                <div className={styles.wrapper}>
                  <div className={styles.imgContent}>
                    <img
                      src="/image/site.jpeg"
                      alt="LOGO"
                      className={styles.img}
                    />
                  </div>
                  <h4 className={styles.text}>
                    <hr className={styles.highLine} />
                    Կայքը մշակման փուլում է,վերադարձեք ավելի ուշ
                    <hr className={styles.downLine} />
                  </h4>
                </div>
              </div>
            }
          />
          <Route
            path="/admin"
            element={
              isAdmin && isAdmin && isAdminCookie == true ? (
                <AlreadyLogined />
              ) : (
                <Admin />
              )
            }
          />

          <Route
            path="/admin/admindashboard"
            element={isAdmin && isAdmin ? <AdminDashboard /> : <FailedAdmin />}>
            <Route
              path="/admin/admindashboard/aboutus/addaboutpage/am"
              element={isAdmin && isAdmin ? <AddAboutAm /> : <FailedAdmin />}
            />
            <Route
              path="/admin/admindashboard/aboutus/addaboutpage/ru"
              element={isAdmin && isAdmin ? <AddAboutRu /> : <FailedAdmin />}
            />
            <Route
              path="/admin/admindashboard/aboutus/addaboutpage/en"
              element={isAdmin && isAdmin ? <AddAboutEn /> : <FailedAdmin />}
            />
            <Route
              path="/admin/admindashboard/addproduct"
              element={
                isAdmin && isAdmin ? <AddProductPage /> : <FailedAdmin />
              }
            />
            <Route
              path="/admin/admindashboard/products"
              element={
                isAdmin && isAdmin ? (
                  <AdminProductsDataTables />
                ) : (
                  <FailedAdmin />
                )
              }
            />
            <Route
              path="/admin/admindashboard/recentProjects"
              element={
                isAdmin && isAdmin ? <AdminRecentProjects /> : <FailedAdmin />
              }
            />
            <Route
              path="/admin/admindashboard/websiteStatus"
              element={
                isAdmin && isAdmin ? <AdminWebSiteStatus /> : <FailedAdmin />
              }
            />
            <Route
              path="/admin/admindashboard/main"
              element={
                isAdmin && isAdmin ? <MainPagePresentation /> : <FailedAdmin />
              }
            />
            <Route
              path="/admin/admindashboard/editProduct/:id"
              element={
                isAdmin && isAdmin ? <EditProductPage /> : <FailedAdmin />
              }
            />
            <Route
              path="/admin/admindashboard/infoAccordeon"
              element={
                isAdmin && isAdmin ? <AdminInfoAccordeon /> : <FailedAdmin />
              }
            />
            <Route
              path="/admin/admindashboard/productsColor"
              element={isAdmin && isAdmin ? <AdminColors /> : <FailedAdmin />}
            />
            <Route
              path="/admin/admindashboard/productsMaterials"
              element={
                isAdmin && isAdmin ? <AdminMaterials /> : <FailedAdmin />
              }
            />
            <Route
              path="/admin/admindashboard/mainInformation"
              element={
                isAdmin && isAdmin ? <MainInformation /> : <FailedAdmin />
              }
            />
            <Route
              path="/admin/admindashboard/partners"
              element={isAdmin && isAdmin ? <AdminPartners /> : <FailedAdmin />}
            />
            <Route
              path="/admin/admindashboard/mainBaners"
              element={isAdmin && isAdmin ? <AdminBanners /> : <FailedAdmin />}
            />
            <Route
              path="/admin/admindashboard/categories"
              element={
                isAdmin && isAdmin ? <AdminCategories /> : <FailedAdmin />
              }
            />
            <Route
              path="/admin/admindashboard/categories"
              element={isAdmin && isAdmin ? <AdminAbout /> : <FailedAdmin />}
            />
            <Route
              path="/admin/admindashboard/aboutUs"
              element={isAdmin && isAdmin ? <AdminAbout /> : <FailedAdmin />}
            />
            <Route
              path="/admin/admindashboard/about/editAbout/:id"
              element={isAdmin && isAdmin ? <EditAbout /> : <FailedAdmin />}
            />
            <Route
              path="/admin/admindashboard/contacts"
              element={isAdmin && isAdmin ? <AdminContact /> : <FailedAdmin />}
            />
          </Route>
        </Routes>
      </div>
    )
  } else if (status == 'online') {
    return (
      <div>
        <Routes>
          <Route path="/" element={<MainWrapper />} />
          <Route path="/allProducts" element={<AllProducts />} />
          <Route path="/recentsProjects" element={<AllRecentProjects />} />
          <Route path="/allProducts/product/:id" element={<ProductCard />} />
          <Route
            path="/recentProjects/project/:id"
            element={<RecentProjectPage />}
          />
          <Route path="*" element={<NotFound />} />
          <Route
            path="/admin"
            element={isAdmin && isAdmin ? <AlreadyLogined /> : <Admin />}
          />

          <Route
            path="/admin/admindashboard"
            element={isAdmin && isAdmin ? <AdminDashboard /> : <FailedAdmin />}>
            <Route
              path="/admin/admindashboard/aboutus/addaboutpage/am"
              element={isAdmin && isAdmin ? <AddAboutAm /> : <FailedAdmin />}
            />
            <Route
              path="/admin/admindashboard/aboutus/addaboutpage/ru"
              element={isAdmin && isAdmin ? <AddAboutRu /> : <FailedAdmin />}
            />
            <Route
              path="/admin/admindashboard/aboutus/addaboutpage/en"
              element={isAdmin && isAdmin ? <AddAboutEn /> : <FailedAdmin />}
            />
            <Route
              path="/admin/admindashboard/addproduct"
              element={
                isAdmin && isAdmin ? <AddProductPage /> : <FailedAdmin />
              }
            />
            <Route
              path="/admin/admindashboard/products"
              element={
                isAdmin && isAdmin ? (
                  <AdminProductsDataTables />
                ) : (
                  <FailedAdmin />
                )
              }
            />
            <Route
              path="/admin/admindashboard/recentProjects"
              element={
                isAdmin && isAdmin ? <AdminRecentProjects /> : <FailedAdmin />
              }
            />
            <Route
              path="/admin/admindashboard/websiteStatus"
              element={
                isAdmin && isAdmin ? <AdminWebSiteStatus /> : <FailedAdmin />
              }
            />
            <Route
              path="/admin/admindashboard/main"
              element={
                isAdmin && isAdmin ? <MainPagePresentation /> : <FailedAdmin />
              }
            />
            <Route
              path="/admin/admindashboard/editProduct/:id"
              element={
                isAdmin && isAdmin ? <EditProductPage /> : <FailedAdmin />
              }
            />
            <Route
              path="/admin/admindashboard/mainInformation"
              element={
                isAdmin && isAdmin ? <MainInformation /> : <FailedAdmin />
              }
            />
            <Route
              path="/admin/admindashboard/infoAccordeon"
              element={
                isAdmin && isAdmin ? <AdminInfoAccordeon /> : <FailedAdmin />
              }
            />
            <Route
              path="/admin/admindashboard/productsColor"
              element={isAdmin && isAdmin ? <AdminColors /> : <FailedAdmin />}
            />
            <Route
              path="/admin/admindashboard/productsMaterials"
              element={
                isAdmin && isAdmin ? <AdminMaterials /> : <FailedAdmin />
              }
            />
            <Route
              path="/admin/admindashboard/partners"
              element={isAdmin && isAdmin ? <AdminPartners /> : <FailedAdmin />}
            />
            <Route
              path="/admin/admindashboard/mainBaners"
              element={isAdmin && isAdmin ? <AdminBanners /> : <FailedAdmin />}
            />
            <Route
              path="/admin/admindashboard/categories"
              element={
                isAdmin && isAdmin ? <AdminCategories /> : <FailedAdmin />
              }
            />
            <Route
              path="/admin/admindashboard/categories"
              element={isAdmin && isAdmin ? <AdminAbout /> : <FailedAdmin />}
            />
            <Route
              path="/admin/admindashboard/aboutUs"
              element={isAdmin && isAdmin ? <AdminAbout /> : <FailedAdmin />}
            />
            <Route
              path="/admin/admindashboard/about/editAbout/:id"
              element={isAdmin && isAdmin ? <EditAbout /> : <FailedAdmin />}
            />
            <Route
              path="/admin/admindashboard/contacts"
              element={isAdmin && isAdmin ? <AdminContact /> : <FailedAdmin />}
            />
          </Route>
        </Routes>
      </div>
    )
  }
}
