import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import './index.css'
import App from './App'
import store from './store/index'
import { BrowserRouter } from 'react-router-dom'

import './18n'
import Loader from './components/Loader/Loader'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Suspense fallback={<Loader />}>
        {' '}
        <App />
      </Suspense>{' '}
    </Provider>{' '}
  </BrowserRouter>
)
