import styles from './Contacts.module.css'
import { TiSocialFacebookCircular } from 'react-icons/ti'
import { BsInstagram } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { useRef, useEffect } from 'react'
import { setContactUs } from '../../store/sections/sectionsAction'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import axios from 'axios'
import { motion } from 'framer-motion'

const leftAnimation = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
  },
}

const rightAnimation = {
  hidden: {
    x: 100,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
  },
}

const bottomAnimation = {
  hidden: {
    y: 100,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
  },
}
const topAnimation = {
  hidden: {
    y: -100,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
  },
}

const bottomItemAnimation = {
  hidden: {
    y: 100,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.2 },
  }),
}
const topItemAnimation = {
  hidden: {
    y: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.2 },
  }),
}

export default function Contacts() {
  const lang = useSelector((state) => state.lang.lang)
  const dispatch = useDispatch()
  const contactRef = useRef(null)
  const [contact, setContact] = useState([])

  const { t, i18n } = useTranslation()
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  useEffect(() => {
    if (contactRef.current) {
      dispatch(setContactUs(contactRef))
    }
  }, [dispatch])

  useEffect(() => {
    setTimeout(() => {
      const contactValue = contactRef.current.offsetTop

      localStorage.setItem('contact', contactValue)
    }, 1000)
  }, [contactRef?.current?.offsetTop])

  useEffect(() => {
    const fetchContact = async () => {
      try {
        const res = await axios.get(
          'https://elwood-2e61a87e55cb.herokuapp.com/contact'
        )
        const filteredDatas =
          res.data &&
          res.data.filter((item) => item.lang === lang.toLowerCase())

        setContact(filteredDatas)
      } catch (error) {
        console.log(error)
      }
    }
    fetchContact()
  }, [lang])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <motion.section
      viewport={{ amount: 0.2 }}
      initial="hidden"
      whileInView="visible"
      ref={contactRef}
      className={styles.section}>
      <div className={styles.container}>
        <div className={styles.left}>
          <div className={`${styles.leftContent}`}>
            <motion.h2 variants={leftAnimation} className={styles.title}>
              {t('contactUs')}
            </motion.h2>

            <div className={styles.infos}>
              {contact?.length === 1 && (
                <>
                  <div className={styles.address}>
                    <motion.h3
                      variants={bottomAnimation}
                      className={styles.addressTitle}>
                      {t('address')}
                    </motion.h3>
                    <motion.span
                      variants={bottomItemAnimation}
                      custom={1}
                      className={styles.addressContent}>
                      {contact[0].location}
                    </motion.span>
                    <motion.span
                      variants={bottomItemAnimation}
                      custom={2}
                      className={styles.addressContent}>
                      {contact[0].index}
                    </motion.span>
                    <motion.span
                      variants={bottomItemAnimation}
                      custom={2}
                      className={styles.addressContent}>
                      {contact[0].city}
                    </motion.span>
                    <motion.span
                      variants={bottomItemAnimation}
                      custom={3}
                      className={styles.addressContent}>
                      {' '}
                      {contact[0].phoneNumber}
                    </motion.span>
                  </div>
                  <div className={styles.officeHourse}>
                    <motion.h3
                      variants={bottomAnimation}
                      className={styles.officeHourseTitle}>
                      {t('officeHourse')}
                    </motion.h3>
                    <motion.span
                      variants={bottomItemAnimation}
                      custom={1}
                      className={styles.officeHourseContent}>
                      {contact[0].daysInWeek}
                    </motion.span>
                    <motion.span
                      variants={bottomItemAnimation}
                      custom={2}
                      className={styles.officeHourseContent}>
                      {contact[0].hours}
                    </motion.span>
                    <motion.span
                      variants={bottomItemAnimation}
                      custom={3}
                      className={styles.officeHourseContent}>
                      {' '}
                      {contact[0].close}
                    </motion.span>
                  </div>
                </>
              )}
            </div>
            <div className={`${styles.flexReverse}`}></div>
            <div className={styles.socialMedia}>
              <div className={styles.socialMediaContent}>
                <motion.h2
                  variants={topAnimation}
                  className={`${styles.socialMediaTitle} ${
                    lang === 'RUS' && styles.rufollowUs
                  }`}>
                  {t('followUs')}
                </motion.h2>

                <motion.div
                  variants={topItemAnimation}
                  className={styles.socialMediaIcons}>
                  <a href="https://www.facebook.com/gaelwood" target="_blank">
                    <TiSocialFacebookCircular
                      className={styles.socialMediaIconFacebook}
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/elwoodfurniture/"
                    target="_blank">
                    <BsInstagram className={styles.socialMediaIconInstagram} />
                  </a>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
        <motion.div variants={rightAnimation} className={styles.right}>
          <iframe
            allowFullScreen
            src="https://yandex.com/map-widget/v1/?ll=44.498470%2C40.227562&mode=whatshere&whatshere%5Bpoint%5D=44.520613%2C40.213243&whatshere%5Bzoom%5D=17&z=14.45"
            width="600"
            height="450"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className={styles.map}
          />
        </motion.div>
      </div>
    </motion.section>
  )
}
