import FilterPanel from './FilterPanel/FilterPanel'
import ProductsHeader from '../../components/ProductsHeader/ProductsHeader'
import { useEffect } from 'react'
import axios from 'axios'
import ProductsFooter from '../../components/ProductsFooter/ProductsFooter'

export default function AllProducts() {
  // useEffect(() => {
  //   async function handleLogout() {
  //     try {
  //       await axios.put('https://elwood-2e61a87e55cb.herokuapp.com/isAdmin', {
  //         isAdmin: 0,
  //       })
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }

  //   handleLogout()
  // }, [])
  return (
    <div>
      <ProductsHeader />
      <FilterPanel />
      <ProductsFooter />
    </div>
  )
}
