import styles from './Footer.module.css'
import { AiFillInstagram } from 'react-icons/ai'
import { FaFacebook } from 'react-icons/fa'
import { BiCopyright } from 'react-icons/bi'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

export default function Footer() {
  const [contact, setContact] = useState([])
  const lang = useSelector((state) => state.lang.lang)
  const [width, setWidth] = useState('')
  const { aboutUs, products, projects, contactUs, partners } = useSelector(
    (state) => state.sections
  )
  const offset = -50

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const { t, i18n } = useTranslation()

  useEffect(() => {
    const fetchContact = async () => {
      try {
        const res = await axios.get(
          'https://elwood-2e61a87e55cb.herokuapp.com/contact'
        )
        const filteredDatas =
          res.data &&
          res.data.filter((item) => item.lang === lang.toLowerCase())

        setContact(filteredDatas)
      } catch (error) {
        console.log(error)
      }
    }
    fetchContact()
  }, [lang])

  return (
    <>
      <footer className={styles.footer}>
        <div className={styles.container}>
          <div className={styles.left}>
            <div className={styles.logoContainer}>
              <img src="/image/site.jpeg" alt="LOGO" className={styles.logo} />
            </div>
            <div className={styles.menuContainer}>
              <h3 className={styles.menuTitle}>{t('menu')}</h3>
              <span
                className={styles.menuItem}
                onClick={() =>
                  aboutUs.current &&
                  window.scrollTo({
                    top: aboutUs.current.offsetTop + offset,
                    behavior: 'smooth',
                  })
                }>
                {t('about')}
              </span>
              <span
                className={styles.menuItem}
                onClick={() =>
                  products.current &&
                  window.scrollTo({
                    top: products?.current?.offsetTop + offset,
                    behavior: 'smooth',
                  })
                }>
                {t('products')}
              </span>
              <span
                className={styles.menuItem}
                onClick={() =>
                  projects.current &&
                  window.scrollTo({
                    top: projects.current.offsetTop + offset,
                    behavior: 'smooth',
                  })
                }>
                {t('projects')}
              </span>
              <span
                className={styles.menuItem}
                onClick={() =>
                  partners.current &&
                  window.scrollTo({
                    top: partners.current.offsetTop + -200,
                    behavior: 'smooth',
                  })
                }>
                {t('partners')}
              </span>
              <span
                className={`${styles.menuItem} ${styles.lastItem}`}
                onClick={() =>
                  contactUs.current &&
                  window.scrollTo({
                    top: contactUs.current.offsetTop + -300,
                    behavior: 'smooth',
                  })
                }>
                {t('contactUs')}
              </span>
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.address}>
              <span className={styles.addressItem}>{contact[0]?.location}</span>
              <span className={styles.addressItem}>{contact[0]?.city}</span>
              <span className={styles.addressItem}>
                {contact[0]?.phoneNumber}
              </span>
            </div>
            <div className={styles.socialMedia}>
              <div className={styles.socialMediaItems}>
                <a href="https://www.facebook.com/gaelwood" target="_blank">
                  <FaFacebook
                    className={`${styles.iconFacebook} ${styles.icon}`}
                  />
                </a>
                <a
                  href="https://www.instagram.com/elwoodfurniture/"
                  target="_blank">
                  <AiFillInstagram
                    className={`${styles.iconInstagram} ${styles.icon}`}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.allRights}>
          <span className={styles.copyRight}>
            <BiCopyright className={styles.iconCopyRight} />
          </span>
          <span className={styles.copyRightText}>
            Case.one 2022-2023.All right reserved.
          </span>
        </div>
      </footer>
    </>
  )
}
