import { useTranslation } from 'react-i18next'
import styles from './Partners.module.css'
import { FiFigma } from 'react-icons/fi'
import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import Description from '../../pages/ProductPage/Description/Description'
import { motion } from 'framer-motion'
import { useDispatch } from 'react-redux'
import { setPartners } from '../../store/sections/sectionsAction'
import './SliderCss.css'
import Slider from 'react-slick'
import { ref, getDownloadURL, listAll } from 'firebase/storage'
import storage from '../../firebase'
import Loader from '../Loader/Loader'

const titleAnimation = {
  hidden: {
    y: -100,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
  },
}

const partnerItemAnimation = {
  hidden: {
    y: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.15 },
  }),
}

export default function Partners() {
  const [width, setWidth] = useState(window.innerWidth)
  const [no, setNo] = useState(1)
  const [imgLink, setImgLink] = useState(null)
  const dispatch = useDispatch()
  const lang = useSelector((state) => state.lang.lang)
  const productsRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const { t, i18n } = useTranslation()

  useEffect(() => {
    if (productsRef.current) {
      dispatch(setPartners(productsRef))
    }
  }, [dispatch])

  useEffect(() => {
    if (lang === 'ENG') {
      setNo(1)
    } else if (lang === 'RUS') {
      setNo(2)
    } else if (lang === 'ARM') {
      setNo(3)
    }
  }, [no, lang])

  useEffect(() => {
    if (lang === 'ENG') {
      setNo(1)
    } else if (lang === 'RUS') {
      setNo(2)
    } else if (lang === 'ARM') {
      setNo(3)
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  }

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const res = await axios.get(
          'https://elwood-2e61a87e55cb.herokuapp.com/partners'
        )
        const imageListRef = ref(storage, 'images/partners')
        const listResult = await listAll(imageListRef)
        const imgLinks =
          res.data &&
          (await Promise.all(
            res.data &&
              res.data.map(async (partner) => {
                const imageRef = listResult.items.find(
                  (itemRef) => itemRef.name === partner?.img
                )
                if (imageRef) {
                  const imageUrl = await getDownloadURL(imageRef)
                  return { ...partner, url: imageUrl }
                } else {
                  return partner
                }
              })
          ))

        const partnersDatas =
          imgLinks &&
          imgLinks.filter(
            (item) =>
              item?.lang === lang.toLowerCase() &&
              Number(item?.no) === Number(no)
          )

        setImgLink(partnersDatas)
      } catch (error) {
        console.log(error)
      }
    }
    fetchPartners()
  }, [no])

  return (
    <motion.div
      viewport={{ amount: 0.2 }}
      initial="hidden"
      whileInView="visible">
      <motion.h1
        ref={productsRef}
        variants={titleAnimation}
        className={styles.mainTitle}>
        {t('partners')}
      </motion.h1>
      <section className={styles.section}>
        {/* <div className={styles.container}> */}
        {width > 510 && (
          <div className={styles.partners}>
            {imgLink &&
              imgLink.map((partner, index) => (
                <motion.div
                  custom={index++}
                  variants={partnerItemAnimation}
                  key={partner.id}
                  className={styles.partner}>
                  <div className={styles.logoContainer}>
                    <img
                      src={partner?.url}
                      alt="Partner"
                      className={`${styles.logo} ${styles.smallLogo}`}
                    />
                  </div>
                  <h2 className={styles.title}>{partner?.title}</h2>
                  <p className={`${styles.desc} ${styles.descMaxWidth}`}>
                    <Description
                      desc={partner?.description.replace(/"/g, '')}
                    />
                  </p>
                </motion.div>
              ))}
          </div>
        )}
        {width < 510 && (
          <div>
            <Slider {...settings}>
              {imgLink &&
                imgLink.map((partner, index) => (
                  <motion.div
                    custom={index++}
                    variants={partnerItemAnimation}
                    key={partner.id}
                    className={styles.partner}>
                    <div className={styles.logoContainer}>
                      <img
                        src={partner?.url}
                        alt="Partner"
                        className={`${styles.logo} ${styles.smallLogo}`}
                      />
                    </div>
                    <h2 className={styles.title}>{partner?.title}</h2>
                    <p className={`${styles.desc} ${styles.descMaxWidth}`}>
                      <Description
                        desc={partner?.description.replace(/"/g, '')}
                      />
                    </p>
                  </motion.div>
                ))}
            </Slider>
          </div>
        )}
        {/* </div> */}
      </section>
      {loading && <Loader />}
    </motion.div>
  )
}
