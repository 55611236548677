import styles from './ProductsFooter.module.css'
import { AiFillInstagram } from 'react-icons/ai'
import { FaFacebook } from 'react-icons/fa'
import { BiCopyright } from 'react-icons/bi'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll'

export default function ProductsFooter({ productsLink }) {
  const navigate = useNavigate()
  const [contact, setContact] = useState([])
  const lang = useSelector((state) => state.lang.lang)
  const [width, setWidth] = useState('')
  const [aboutUs, setAboutUs] = useState(0)
  const [products, setProducts] = useState(0)
  const [projects, setProjects] = useState(0)
  const [contactLink, setContactLink] = useState(0)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const { t, i18n } = useTranslation()

  useEffect(() => {
    const fetchContact = async () => {
      try {
        const res = await axios.get(
          'https://elwood-2e61a87e55cb.herokuapp.com/contact'
        )
        const filteredDatas =
          res.data &&
          res.data.filter((item) => item.lang === lang.toLowerCase())

        setContact(filteredDatas)
      } catch (error) {
        console.log(error)
      }
    }
    fetchContact()
  }, [lang])

  useEffect(() => {
    const contactValue = localStorage.getItem('contact')
    const aboutValue = localStorage.getItem('aboutUs')
    const productsValue = localStorage.getItem('products')
    const projectsValue = localStorage.getItem('projects')

    const psContact = parseInt(contactValue, 10)
    const psAboutUs = parseInt(aboutValue, 10)
    const psProducts = parseInt(productsValue, 10)
    const psProjects = parseInt(projectsValue, 10)

    setAboutUs(psAboutUs)
    setContactLink(psContact)
    setProducts(psProducts)
    setProjects(psProjects)
  }, [])

  const handleNavigate = async (current) => {
    await navigate('/')

    scroll.scrollTo(current, {
      duration: 1200,
      smooth: true,
      offset: -50,
    })
  }

  return (
    <>
      <footer className={styles.footer}>
        <div className={styles.container}>
          <div className={styles.left}>
            <div className={styles.logoContainer}>
              <img src="/image/site.jpeg" alt="LOGO" className={styles.logo} />
            </div>
            <div className={styles.menuContainer}>
              <h3 className={styles.menuTitle}>{t('menu')}</h3>
              <span
                className={styles.menuItem}
                onClick={() => aboutUs && handleNavigate(aboutUs)}>
                {t('about')}
              </span>
              <span
                className={styles.menuItem}
                onClick={() => products && handleNavigate(products)}>
                {t('products')}
              </span>
              <span
                className={styles.menuItem}
                onClick={() => projects && handleNavigate(projects)}>
                {t('projects')}
              </span>
              <span
                className={styles.menuItem}
                onClick={() => contactLink && handleNavigate(contactLink)}>
                {t('contactUs')}
              </span>
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.address}>
              <span className={styles.addressItem}>{contact[0]?.location}</span>
              <span className={styles.addressItem}>{contact[0]?.city}</span>
              <span className={styles.addressItem}>
                {contact[0]?.phoneNumber}
              </span>
            </div>
            <div className={styles.socialMedia}>
              <div className={styles.socialMediaItems}>
                <a href="https://www.facebook.com/gaelwood" target="_blank">
                  <FaFacebook
                    className={`${styles.iconFacebook} ${styles.icon}`}
                  />
                </a>
                <a
                  href="https://www.instagram.com/elwoodfurniture/"
                  target="_blank">
                  <AiFillInstagram
                    className={`${styles.iconInstagram} ${styles.icon}`}
                  />
                </a>
              </div>
              {/* <div className={styles.sendContanier}>
                <input
                  type="text"
                  className={styles.sendEmailInp}
                  placeholder="Enter your email"
                />
                <BiRightArrowAlt className={styles.sendIcon} />
              </div> */}
            </div>
          </div>
        </div>
        <div className={styles.allRights}>
          <span className={styles.copyRight}>
            <BiCopyright className={styles.iconCopyRight} />
          </span>
          <span className={styles.copyRightText}>
            Case.one 2022-2023.All right reserved.
          </span>
        </div>
      </footer>
    </>
  )
}
