import DataTable from 'react-data-table-component'
import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Switch from '@mui/material/Switch'
import { IconButton } from '@mui/material'
import { styled } from '@mui/system'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import styles from './AdminColors.module.css'
import SendIcon from '@mui/icons-material/Send'
import { SketchPicker } from 'react-color'
import LoadingButton from '@mui/lab/LoadingButton'
import axios from 'axios'
import DeleteIcon from '@mui/icons-material/Delete'
import CreateIcon from '@mui/icons-material/Create'
import convert from 'color-convert'
import { toast, ToastContainer } from 'react-toastify'
import { Modal as AntDModal } from 'antd'
import Loader from '../Loader/Loader'
import TextField from '@mui/material/TextField'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: -10,
  p: 4,
}

export default function AdminColors() {
  const [loading, setLoading] = useState(true)
  const [itemId, setItemId] = useState(null)
  const [colors, setColors] = useState([])
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [color, setColor] = useState({
    color: '',
    hex: '',
    rgb: '',
    colorTitle: '',
    disabled: 0,
  })
  const [sendColorLoading, setSendColorLoading] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [open, setOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const [editValue, setEditValue] = useState({
    color: '',
    hex: '',
    rgb: '',
    colorTitle: '',
    disabled: 0,
  })
  const [editColorId, setEditColorId] = useState(null)
  const [editColorLoading, setEditColorLoading] = useState(false)

  const StyledIconButton = styled(IconButton)({
    '&:hover': {
      color: 'red',
    },
  })

  async function handleDeleteCategory(id) {
    try {
      await axios
        .delete(`https://elwood-2e61a87e55cb.herokuapp.com/deletecolor/${id}`)
        .then(() => toast.success('Color deleted success !'))

      const updatedColors = await axios.get(
        'https://elwood-2e61a87e55cb.herokuapp.com/colors'
      )
      const parsedDatas =
        updatedColors.data &&
        updatedColors.data.map((item) => {
          return {
            id: item.id,
            color: item.color,
            rgb: JSON.parse(item.rgb),
            disabled: item.disabled,
          }
        })

      setColors(parsedDatas)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const handleSwitchChange = (id) => (e) => {
    const value = e.target.checked ? 1 : 0
    setIsChecked(value)
    setItemId(id)
    try {
      axios
        .put('https://elwood-2e61a87e55cb.herokuapp.com/editcolor', {
          isChecked: value,
          id: id,
        })
        .then(() => toast.success('Color edited success !'))
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = (newColor) => {
    const rgbColors = []
    for (let color in newColor.rgb) {
      rgbColors.push(newColor.rgb[color])
    }
    rgbColors.splice(-1)
    const colorName = convert.rgb.keyword(rgbColors)
    setColor((prev) => ({
      ...prev,
      color: colorName,
      rgb: rgbColors,
      hex: newColor.hex,
    }))
  }

  const handleChangeEdit = (newColor) => {
    const rgbColors = []
    for (let color in newColor.rgb) {
      rgbColors.push(newColor.rgb[color])
    }
    rgbColors.splice(-1)
    const colorName = convert.rgb.keyword(rgbColors)

    setEditValue((prev) => ({
      ...prev,
      color: colorName,
      rgb: rgbColors,
      hex: newColor.hex,
    }))
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const showModal = () => {
    setDeleteOpen(true)
  }

  const hideModal = () => {
    setDeleteOpen(false)
  }

  const handleCloseEdit = () => {
    setEditOpen(false)
    setEditValue({})
    setEditColorId(null)
  }

  const label = { inputProps: { 'aria-label': 'Switch demo' } }
  const columns = [
    {
      name: 'Id',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Color',
      selector: (row) => (
        <div className={styles.colorRowContainer}>
          <div
            style={{
              backgroundColor: Array.isArray(row.rgb)
                ? `rgb(${row.rgb.join(', ')})`
                : '',
              border: row.color === '#ffffff' && '1px solid #ccc',
            }}
            className={styles.color}></div>
          <p>{row.colorTitle + ' ' + `(${row.color})`}</p>
        </div>
      ),
    },
    {
      name: 'Disabled',
      selector: (row) => (
        <div>
          {row.disabled ? (
            <Switch
              {...label}
              defaultChecked
              onChange={handleSwitchChange(row.id)}
            />
          ) : (
            <Switch {...label} onChange={handleSwitchChange(row.id)} />
          )}
        </div>
      ),
    },
    {
      name: 'Actions',
      selector: (row) => (
        <Stack direction="row" spacing={1}>
          <StyledIconButton aria-label="delete">
            <DeleteIcon
              color="error"
              onClick={(e) => {
                setItemId(row.id)
                showModal()
              }}
            />
          </StyledIconButton>
          <StyledIconButton aria-label="edit">
            <CreateIcon
              color="warning"
              onClick={(e) => {
                setEditColorId(row.id)
                setEditValue((prev) => ({
                  ...prev,
                  colorTitle: row.colorTitle ? row.colorTitle : '',
                }))
                setEditOpen(true)
              }}
            />
          </StyledIconButton>
        </Stack>
      ),
    },
  ]

  const fetchColors = async () => {
    try {
      const res = await axios.get(
        'https://elwood-2e61a87e55cb.herokuapp.com/colors'
      )
      const parsedDatas =
        res.data &&
        res.data.map((item) => {
          return {
            id: item.id,
            color: item.color,
            rgb: JSON.parse(item.rgb),
            disabled: item.disabled,
            colorTitle: item.colorTitle,
          }
        })
      setColors(parsedDatas)
      setLoading(false)
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchColors()
  }, [])

  async function handleClick(e) {
    e.preventDefault()
    setSendColorLoading(true)
    try {
      await axios
        .post('https://elwood-2e61a87e55cb.herokuapp.com/addcolor', {
          color: color?.color,
          disabled: color?.disabled,
          rgb: JSON.stringify(color?.rgb),
          colorTitle: color?.colorTitle,
        })
        .then(() => {
          setColor({
            color: '',
            hex: '',
            rgb: '',
            disabled: 0,
            colorTitle: '',
          })
          toast.success('Color created success !')
        })
      const updatedColors = await axios.get(
        'https://elwood-2e61a87e55cb.herokuapp.com/colors'
      )
      setColors(
        updatedColors.data.map((item) => {
          return {
            id: item.id,
            color: item.color,
            rgb: JSON.parse(item.rgb),
            disabled: item.disabled,
          }
        })
      )
      setSendColorLoading(false)
      setOpen(false)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const handleEditColor = async () => {
    try {
      setEditColorLoading(true)
      await axios
        .put(
          `https://elwood-2e61a87e55cb.herokuapp.com/editColorName/${editColorId}`,
          {
            newColor: editValue.color,
            newRgb: JSON.stringify(editValue.rgb),
            colorTitle: editValue.colorTitle,
          }
        )
        .then(({ data }) => {
          toast.success(data, {
            toastClassName: styles.myToast,
          })
          handleCloseEdit()
          fetchColors()
          setEditColorLoading(false)
        })
        .catch((error) => console.log(error.message))
    } catch (error) {
      console.log('UPDATE ERROR COLORS')
      console.log(error.message)
    }
  }

  return (
    <>
      <DataTable
        title="Colors List"
        columns={columns}
        data={colors}
        fixedHeader
        fixedHeaderScrollHeight="450px"
        pagination
        selectableRows
        selectableRowsHighlight
        highlightOnHover
        actions={
          <div>
            <Stack spacing={2} direction="row">
              <Button variant="outlined" onClick={handleOpen}>
                + Add Color
              </Button>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    className={styles.titleModal}>
                    Create New Color
                  </Typography>
                  <div className={styles.colorSwatches}>
                    <SketchPicker color={color.hex} onChange={handleChange} />
                  </div>
                  <Box
                    className={styles.addInpCateogoryContainer}
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off">
                    <TextField
                      id="standard-basic"
                      label="Armenia Color Title"
                      value={color?.colorTitle}
                      variant="standard"
                      color="primary"
                      name="category"
                      onChange={(e) =>
                        setColor((prev) => ({
                          ...prev,
                          colorTitle: e.target.value,
                        }))
                      }
                    />
                  </Box>
                  <Box className={styles.sendButtonContainer}>
                    <Box sx={{ '& > button': { m: 1 } }}>
                      <LoadingButton
                        onClick={handleClick}
                        endIcon={<SendIcon />}
                        loading={sendColorLoading}
                        loadingPosition="end"
                        variant="contained">
                        <span>Send</span>
                      </LoadingButton>
                    </Box>
                  </Box>
                </Box>
              </Modal>
            </Stack>
          </div>
        }
        subHeader
        subHeaderAlign="center"
      />
      <AntDModal
        title="You are sure delete category"
        open={deleteOpen}
        onOk={() => {
          handleDeleteCategory(itemId)
          hideModal()
        }}
        onCancel={hideModal}
        okText="Delete"
        cancelText="Cancel"></AntDModal>
      <ToastContainer />
      <Modal
        open={editOpen}
        onClose={handleCloseEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className={styles.titleModal}>
            Edit Color
          </Typography>
          <div className={styles.colorSwatches}>
            <SketchPicker color={editValue.hex} onChange={handleChangeEdit} />
          </div>
          <Box
            className={styles.addInpCateogoryContainer}
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off">
            <TextField
              id="standard-basic"
              label="Edit Armenia Color Title"
              value={editValue?.colorTitle}
              variant="standard"
              color="primary"
              name="colorTitle"
              onChange={(e) =>
                setEditValue((prev) => ({
                  ...prev,
                  colorTitle: e.target.value,
                }))
              }
            />
          </Box>
          <Box className={styles.sendButtonContainer}>
            <Box sx={{ '& > button': { m: 1 } }}>
              <LoadingButton
                disabled={editValue.color ? false : true}
                onClick={handleEditColor}
                endIcon={<SendIcon />}
                loading={editColorLoading}
                loadingPosition="end"
                variant="contained">
                <span>Update</span>
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Modal>
      {loading && <Loader />}
    </>
  )
}
