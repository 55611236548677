import styles from './EditProductPage.module.css'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi'
import { MdAddCircle, MdColorLens, MdOutlineHeight } from 'react-icons/md'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { TbFlag3Filled } from 'react-icons/tb'
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import WhatshotIcon from '@mui/icons-material/Whatshot'
import NewspaperIcon from '@mui/icons-material/Newspaper'
import { IoIosPricetag } from 'react-icons/io'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { RxWidth } from 'react-icons/rx'
import { Editor } from '@tinymce/tinymce-react'
import DescriptionIcon from '@mui/icons-material/Description'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { v4 as uuidv4 } from 'uuid'
import { AiFillPicture } from 'react-icons/ai'
import Switch from '@mui/material/Switch'
import { GiSightDisabled } from 'react-icons/gi'
import RefreshIcon from '@mui/icons-material/Refresh'
import { TbRefreshDot } from 'react-icons/tb'
import storage from '../../firebase'
import {
  uploadBytes,
  ref,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage'
import { toast, ToastContainer } from 'react-toastify'
import imageCompression from 'browser-image-compression'
import { Select as AntSelect, Tag } from 'antd'
import { Button as AntdButton, Dropdown } from 'antd'

const tagRender = (props) => {
  const { label, value, closable, onClose } = props

  const onPreventMouseDown = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }
  return (
    <Tag
      color={value}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
      }}>
      {label}
    </Tag>
  )
}
const tagRenderMaterial = (props) => {
  const { label, value, closable, onClose } = props
  const onPreventMouseDown = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }
  return (
    <Tag
      color={value}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
        color: 'black',
        background: '#ccc',
      }}>
      {label}
    </Tag>
  )
}

export default function AddProductPage() {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const productId = pathname.split('/')[4]
  const [webpSupported, setWebpSupported] = useState(false)
  const [files, setFiles] = useState(null)
  const [content, setContent] = useState('')
  const [open, setOpen] = useState('')
  const [sendCategoryLoading, setSendCategoryLoading] = useState(false)
  const [sendMaterialLoading, setSendMaterialLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [materials, setMaterials] = useState([])
  const [colors, setColors] = useState([])
  const [product, setProduct] = useState([])
  const [productMaterial, setProductMaterial] = useState([])
  const [defaultMaterials, setDefaultMaterials] = useState([])
  const [defaultCategories, setDefaultCategories] = useState([])
  const [defaultsColors, setDefaultsColors] = useState([])
  const [addCategory, setAddCategory] = useState({
    category: '',
    disabled: 0,
  })
  const [addMaterial, setAddMaterial] = useState({
    material: '',
    disabled: 0,
  })
  const [sendingValues, setSendingValues] = useState({})
  const [sendingCategories, setSendingCategories] = useState([])
  const [sendingMaterials, setSendingMaterials] = useState([])
  const [sendingColors, setSendingColors] = useState([])
  const [language, setLanguage] = useState('arm')
  const [languageItems, setLanguageItems] = useState([
    {
      key: '1',
      label: (
        <a rel="noopener noreferrer" onClick={() => setLanguage('arm')}>
          Հայերեն
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a rel="noopener noreferrer" onClick={() => setLanguage('rus')}>
          Ռուսերեն
        </a>
      ),
    },
    {
      key: '3',
      label: (
        <a rel="noopener noreferrer" onClick={() => setLanguage('eng')}>
          Անգլերեն
        </a>
      ),
    },
  ])

  useEffect(() => {
    if (product) {
      setSendingValues({
        name: product[`name_${language}`] ? product[`name_${language}`] : '',
        status: product.status,
        price: product[`price_${language}`] ? product[`price_${language}`] : '',
        category:
          product[`category_${language}`] &&
          product[`category_${language}`].length > 0
            ? product[`category_${language}`]
            : [],
        material:
          product[`material_${language}`] &&
          product[`material_${language}`].length > 0
            ? product[`material_${language}`]
            : [],
        colors:
          product[`colors_${language}`] &&
          product[`colors_${language}`].length > 0
            ? product[`colors_${language}`]
            : [],
        imgs:
          product[`imgs_${language}`] && product[`imgs_${language}`].length > 0
            ? product[`imgs_${language}`]
            : [],
        description: product[`description_${language}`]
          ? product[`description_${language}`]
          : '',
        height: product[`height_${language}`]
          ? product[`height_${language}`]
          : '',
        width: product[`width_${language}`] ? product[`width_${language}`] : '',
        disabled: product.disabled,
        oldPrice: product[`oldPrice_${language}`]
          ? product[`oldPrice_${language}`]
          : 0,
        depth: product[`depth_${language}`] ? product[`depth_${language}`] : '',
      })
    }
  }, [product])

  const fetchCategories = async () => {
    try {
      const res = await axios.get(
        'https://elwood-2e61a87e55cb.herokuapp.com/categories'
      )
      const filteredDatas =
        res.data.length &&
        res.data
          .filter(
            (item) =>
              !item.disabled &&
              item.lang.toLowerCase() === language.toLowerCase()
          )
          .map((item) => {
            return { value: item.category }
          })

      setOptions(filteredDatas ? filteredDatas : [])
    } catch (error) {
      toast.error(error.message)
    }
  }

  const fetchMaterials = async () => {
    try {
      const res = await axios.get(
        'https://elwood-2e61a87e55cb.herokuapp.com/materials'
      )
      const filteredDatas =
        res.data.length &&
        res.data
          .filter(
            (item) =>
              !item.disabled &&
              item.lang.toLowerCase() === language.toLowerCase()
          )
          .map((item) => {
            return {
              value: item.material,
            }
          })
      setMaterials(filteredDatas ? filteredDatas : [])
    } catch (error) {
      toast.error(error.message)
    }
  }

  const fetchProducts = async () => {
    try {
      const res = await axios.get(
        'https://elwood-2e61a87e55cb.herokuapp.com/products'
      )
      const productsWithParsedJSON =
        res.data &&
        res.data
          .map((item) => {
            const imgs = item[`imgs_${language}`]
              ? JSON.parse(item[`imgs_${language}`])
              : []
            const colors = item[`colors_${language}`]
              ? JSON.parse(item[`colors_${language}`])
              : []
            const materials = item[`material_${language}`]
              ? JSON.parse(item[`material_${language}`])
              : []
            const category = item[`category_${language}`]
              ? JSON.parse(item[`category_${language}`])
              : []

            return {
              ...item,
              [`imgs_${language}`]: imgs,
              [`colors_${language}`]: colors,
              [`material_${language}`]: materials,
              [`category_${language}`]: category,
            }
          })
          .filter((item) => item.id === +productId)

      const materialsState =
        productsWithParsedJSON[0][`material_${language}`].length > 0
          ? productsWithParsedJSON[0][`material_${language}`].map(
              (item) => item.material
            )
          : []
      const categoryState =
        productsWithParsedJSON[0][`category_${language}`].length > 0
          ? productsWithParsedJSON[0][`category_${language}`].map(
              (item) => item.category
            )
          : []
      const colorsState =
        productsWithParsedJSON[0][`colors_${language}`].length > 0
          ? productsWithParsedJSON[0][`colors_${language}`].map(
              (item) => item.color
            )
          : []

      setDefaultMaterials(materialsState)
      setSendingMaterials(materialsState)
      setDefaultCategories(categoryState)
      setSendingCategories(categoryState)
      setSendingColors(colorsState)
      setDefaultsColors(colorsState)
      setProduct(productsWithParsedJSON[0])
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    fetchProducts()
  }, [])

  useEffect(() => {
    setSendingValues([])
    fetchProducts()
    fetchCategories()
    fetchMaterials()
  }, [language])

  const label = { inputProps: { 'aria-label': 'Switch demo' } }

  const handleDeleteImage = (img) => {
    const imageRefJpeg = ref(storage, img.jpeg)
    const imageRefWebp = ref(storage, img.webp)

    deleteObject(imageRefJpeg).then(() =>
      console.log('Jpeg has been delete sucess')
    )
    deleteObject(imageRefWebp).then(() =>
      console.log('Webp has been elete success')
    )

    setSendingValues((prev) => ({
      ...prev,
      imgs: [
        ...sendingValues.imgs.filter((filteredImg) => filteredImg !== img),
      ],
    }))
  }

  const handleEditorChange = (content, editor) => {
    setSendingValues((prev) => ({ ...prev, description: content }))
  }

  const handleChangeMaterial = (selectedMaterial) => {
    setSendingMaterials([...selectedMaterial])
  }

  const handleChangeCategory = (selectedCategory) => {
    setSendingCategories([...selectedCategory])
  }
  const handleChangeColor = (selectedColor) => {
    setSendingColors([...selectedColor])
  }

  const handleSendValues = async (e) => {
    e.preventDefault()

    let categoriesArray = []
    let materialsArray = []
    let colorsArray = []
    const url = `https://elwood-2e61a87e55cb.herokuapp.com/editProduct/${language}/${productId}`

    if (Array.isArray(sendingCategories) && sendingCategories.length > 0) {
      const res = await axios.get(
        'https://elwood-2e61a87e55cb.herokuapp.com/categories'
      )
      const filteredDatas =
        res.data.length &&
        res.data
          .filter((item) => !item.disabled)
          .filter((item) => sendingCategories.includes(item.category))

      categoriesArray = filteredDatas
    }

    if (Array.isArray(sendingMaterials) && sendingMaterials.length > 0) {
      const res = await axios.get(
        'https://elwood-2e61a87e55cb.herokuapp.com/materials'
      )
      const filteredDatas =
        res.data.length &&
        res.data
          .filter((item) => !item.disabled)
          .filter((item) => sendingMaterials.includes(item.material))

      materialsArray = filteredDatas
    }
    if (Array.isArray(sendingColors) && sendingColors.length > 0) {
      const res = await axios.get(
        'https://elwood-2e61a87e55cb.herokuapp.com/colors'
      )
      const filteredDatas =
        res.data.length &&
        res.data
          .filter((item) => !item.disabled)
          .filter((item) => sendingColors.includes(item.color))
          .map((item) => {
            return { color: item.color, label: item.color, value: item.color }
          })

      colorsArray = filteredDatas
    }

    try {
      await axios
        .put(url, {
          ...sendingValues,
          material: JSON.stringify(materialsArray),
          category: JSON.stringify(categoriesArray),
          colors: JSON.stringify(colorsArray),
          imgs: sendingValues.imgs.length && JSON.stringify(sendingValues.imgs),
        })
        .then(() => {
          toast.success('Product has been edited successfully !')

          setTimeout(() => {
            navigate('/admin/admindashboard/products')
          }, 1000)
        })
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    const container = document.createElement('p')

    container.innerHTML = content

    const descriptionElement = container.querySelector('p')
    const description = descriptionElement && descriptionElement.outerHTML

    setSendingValues((prev) => ({ ...prev, description: description }))
  }, [content])

  const onInputChange = (e) => {
    setFiles(e.target.files)
  }

  async function uploadImgProduct() {
    let newFileNames = []
    let downloadURLs = []

    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      const newFileName = uuidv4() + '.' + file.name.split('.').pop()
      newFileNames.push(newFileName)

      const jpegImageRef = ref(
        storage,
        `images/testProducts/${newFileName}.jpeg`
      )
      const webpImageRef = ref(
        storage,
        `images/testProducts/${newFileName}.webp`
      )

      try {
        const compressedImage = await imageCompression(file, {
          maxSize: 500 * 1024,
          maxWidthOrHeight: 800,
        })

        const jpegCompressedImage = await imageCompression(compressedImage, {
          fileType: 'jpeg',
        })

        const webpCompressedImage = await imageCompression(compressedImage, {
          fileType: 'webp',
        })

        await uploadBytes(jpegImageRef, jpegCompressedImage)
        await uploadBytes(webpImageRef, webpCompressedImage)

        const jpegDownloadURL = await getDownloadURL(jpegImageRef)
        const webpDownloadURL = await getDownloadURL(webpImageRef)

        const imgObj = { jpeg: jpegDownloadURL, webp: webpDownloadURL }
        downloadURLs.push(imgObj)
      } catch (error) {
        toast.error(error.message)
      }
    }

    setSendingValues((prev) => ({
      ...prev,
      imgs: [...prev.imgs, ...downloadURLs],
    }))
  }

  function handleSubmit(e) {
    e.preventDefault()
    uploadImgProduct()
  }

  const handleInputChange = (e) => {
    setAddCategory((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }
  // useEffect(() => {
  //   setProductMaterial(
  //     sendingValues.material &&
  //       sendingValues.material.map((item) => item.material)
  //   )
  // }, [sendingValues])

  useEffect(() => {
    fetchCategories()
  }, [])

  useEffect(() => {
    fetchMaterials()
  }, [])

  useEffect(() => {
    const fetchColors = async () => {
      try {
        const res = await axios.get(
          'https://elwood-2e61a87e55cb.herokuapp.com/colors'
        )
        const filteredDatas =
          res.data.length && res.data.filter((item) => !item.disabled)

        if (Array.isArray(filteredDatas) && filteredDatas.length > 0) {
          filteredDatas.map((item) =>
            setColors((prev) => [
              ...prev,
              {
                label: item.color,
                value: item.color.toLowerCase(),
                color: item.color,
              },
            ])
          )
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    fetchColors()
  }, [])

  const defaultProps = {
    options: options,
    getOptionLabel: (option) => option.category,
    key: uuidv4(),
  }
  const defaultPropsMaterials = {
    options: materials,
    getOptionLabel: (option) => option.material,
  }

  const handleOpenCategory = () => setOpen('addCategory')
  const handleCloseCategory = () => {
    setOpen('')
    setAddCategory({ category: '', disabled: 0 })
  }
  const handleOpenMaterial = () => setOpen('addMaterial')
  const handleCloseMaterial = () => {
    setOpen('')
    setAddMaterial({ material: '', disabled: 0 })
  }

  const handleSwitchChange = (e) => {
    setSendingValues((prev) => ({
      ...prev,
      disabled: prev.disabled ? 0 : 1,
    }))
  }

  function handleMaterialChange(e) {
    setAddMaterial((prev) => ({ ...prev, material: e.target.value }))
  }

  const handleFilePicker = (callback, value, meta) => {
    if (meta.filetype === 'image') {
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/jpeg')
      input.onchange = function () {
        const file = this.files[0]
        callback(`/image/main/${file.name}`)
      }
      input.click()
    }
  }

  useEffect(() => {
    const canvas = document.createElement('canvas')
    if (canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0) {
      setWebpSupported(true)
    } else {
      setWebpSupported(false)
    }
  }, [])

  return (
    <section className={styles.section}>
      <div className={styles.containerHeader}>
        <header className={styles.header}>
          <div className={styles.leftHeader}>
            <h1>Edit Product</h1>
          </div>
          <div className={styles.rightHeader}>
            <div className={styles.backContainer}>
              <Breadcrumbs aria-label="breadcrumb">
                <RouterLink to="/admin/admindashboard/products">
                  <div className={styles.backContainer}>
                    <HiOutlineArrowNarrowLeft className={styles.backIcon} />
                    Go Back
                  </div>
                </RouterLink>
                <button onClick={handleSendValues} className={styles.btn}>
                  <MdAddCircle
                    onClick={handleSendValues}
                    className={styles.iconBtn}
                  />
                  Edit Product
                </button>
              </Breadcrumbs>
            </div>
          </div>
        </header>
      </div>
      <div className={styles.container}>
        <div className={styles.disabledContainer}>
          <Dropdown
            menu={{
              items: languageItems,
            }}
            placement="bottom"
            arrow={{
              pointAtCenter: true,
            }}>
            <AntdButton>
              {language === 'arm'
                ? 'Հայերեն'
                : language === 'rus'
                ? 'Ռուսերեն'
                : language === 'eng'
                ? 'Անգլերեն'
                : ''}
            </AntdButton>
          </Dropdown>
          <div className={styles.disabled}>
            <label className={styles.disabledLabel}>
              <GiSightDisabled className={styles.disabledIcon} />
              Disabled:
            </label>
            <Switch
              {...label}
              checked={sendingValues.disabled === 1}
              onChange={handleSwitchChange}
            />
          </div>
        </div>
        <div className={styles.formNameContainer}>
          <label className={styles.labelName}>
            <TbFlag3Filled className={styles.productNameIcon} />
            Product Name:
          </label>
          <Box
            sx={{
              width: 500,
              maxWidth: '100%',
            }}>
            <TextField
              label="Name"
              value={!sendingValues.name ? '' : sendingValues.name}
              onChange={(e) =>
                setSendingValues((prev) => ({
                  ...prev,
                  name: e.target.value,
                }))
              }
              id="outlined-size-small"
              size="small"
              fullWidth
            />
          </Box>
        </div>
        <div className={styles.statusContainer}>
          <label className={styles.labelStatus}>
            <MilitaryTechIcon />
            Status:
          </label>
          <div
            className={`${styles.status_es} ${
              sendingValues?.status === 1 && styles.statusRef
            }`}
            onClick={() =>
              setSendingValues((prev) => ({ ...prev, status: 1 }))
            }>
            <Box sx={{ width: 100 }}>
              <Grid
                container
                justifyContent="center"
                className={styles.statusIconContainer}>
                <Tooltip title="Hite" placement="top">
                  <WhatshotIcon
                    onClick={() =>
                      setSendingValues((prev) => ({ ...prev, status: 1 }))
                    }
                    className={styles.statusIcon}
                  />
                </Tooltip>
                <span
                  onClick={() =>
                    setSendingValues((prev) => ({ ...prev, status: 1 }))
                  }>
                  Hite
                </span>
              </Grid>
            </Box>
          </div>
          <div
            className={`${styles.status_es} ${
              sendingValues?.status === 2 && styles.statusRef
            }`}
            onClick={() =>
              setSendingValues((prev) => ({ ...prev, status: 2 }))
            }>
            <Box sx={{ width: 100 }}>
              <Grid
                container
                justifyContent="center"
                className={styles.statusIconContainer}>
                <Tooltip title="News" placement="top">
                  <NewspaperIcon
                    onClick={() =>
                      setSendingValues((prev) => ({ ...prev, status: 2 }))
                    }
                    className={styles.statusIcon}
                  />
                </Tooltip>
                <span
                  onClick={() =>
                    setSendingValues((prev) => ({ ...prev, status: 2 }))
                  }>
                  News
                </span>
              </Grid>
            </Box>
          </div>
          {/* <div
            className={`${styles.status_es} ${
              sendingValues?.status === 3 && styles.statusRef
            }`}
            onClick={() =>
              setSendingValues((prev) => ({ ...prev, status: 3 }))
            }>
            <Box sx={{ width: 100 }}>
              <Grid
                container
                justifyContent="center"
                className={styles.statusIconContainer}>
                <Tooltip title="Soon" placement="top">
                  <StarIcon
                    onClick={() =>
                      setSendingValues((prev) => ({ ...prev, status: 3 }))
                    }
                    className={styles.statusIcon}
                  />
                </Tooltip>
                <span
                  onClick={() =>
                    setSendingValues((prev) => ({ ...prev, status: 3 }))
                  }>
                  Soon
                </span>
              </Grid>
            </Box>
          </div>
          <div
            className={`${styles.status_es} ${
              sendingValues.status === 4 && styles.statusRef
            }`}
            onClick={() =>
              setSendingValues((prev) => ({ ...prev, status: 4 }))
            }>
            <Box sx={{ width: 100 }}>
              <Grid
                container
                justifyContent="center"
                className={styles.statusIconContainer}>
                <Tooltip title="Recent Project" placement="top">
                  <NewReleasesIcon
                    onClick={() =>
                      setSendingValues((prev) => ({ ...prev, status: 4 }))
                    }
                    className={styles.statusIcon}
                  />
                </Tooltip>
                <span
                  onClick={() =>
                    setSendingValues((prev) => ({ ...prev, status: 4 }))
                  }>
                  Recent
                </span>
              </Grid>
            </Box>
          </div> */}
          <div
            className={`${styles.status_es} ${
              sendingValues?.status === 0 && styles.statusRef
            }`}
            onClick={() =>
              setSendingValues((prev) => ({ ...prev, status: 0 }))
            }>
            <Box sx={{ width: 100 }}>
              <Grid
                container
                justifyContent="center"
                className={`${styles.statusIconContainer} ${styles.refreshContainer}`}>
                <Tooltip title="Reset" placement="top">
                  <RefreshIcon
                    onClick={() =>
                      setSendingValues((prev) => ({ ...prev, status: 0 }))
                    }
                    className={`${styles.statusIcon} ${styles.restart}`}
                  />
                </Tooltip>
                <span
                  onClick={() =>
                    setSendingValues((prev) => ({ ...prev, status: 0 }))
                  }>
                  Reset
                </span>
              </Grid>
            </Box>
          </div>
        </div>

        <div className={styles.priceContainer}>
          <label className={styles.labelPrice}>
            <IoIosPricetag className={styles.priceIcon} />
            Price:
          </label>
          <TextField
            id="standard-textarea"
            label="Price"
            value={!sendingValues.price ? '' : sendingValues.price}
            onChange={(e) =>
              setSendingValues((prev) => ({ ...prev, price: e.target.value }))
            }
            placeholder="Enter product price"
            multiline
            variant="standard"
          />
        </div>
        <div className={styles.priceContainer}>
          <label className={styles.labelOldPrice}>
            <TbRefreshDot className={styles.oldPrice} />
            Old Price. Not necessary
          </label>
          <TextField
            id="standard-textarea"
            label="Old Price"
            value={!sendingValues.oldPrice ? '' : sendingValues.oldPrice}
            onChange={(e) =>
              setSendingValues((prev) => ({
                ...prev,
                oldPrice: e.target.value,
              }))
            }
            placeholder="Enter old price"
            multiline
            variant="standard"
          />
        </div>
        <div className={styles.categoriesContainer}>
          <label className={styles.categoryLabel}>Category:</label>
          {sendingValues.category && (
            <AntSelect
              mode="multiple"
              showArrow
              tagRender={tagRenderMaterial}
              onChange={handleChangeCategory}
              style={{
                width: '100%',
              }}
              options={options}
              defaultValue={defaultCategories}
            />
          )}
          {/* <Stack
            className={styles.zIndexCategory}
            spacing={1}
            sx={{ width: 300 }}>
            <Autocomplete
              {...defaultProps}
              id="controlled-demo"
              value={sendingValues.category}
              onChange={(event, newValue) => {
                setSendingValues((prev) => ({ ...prev, category: newValue }))
              }}
              renderInput={(params) => (
                <TextField {...params} label="Categories" variant="standard" />
              )}
            />
          </Stack> */}
        </div>
        <div className={styles.colorsContainer}>
          <label className={styles.colorsLable}>
            <MdColorLens className={styles.colorsIcon} />
            Colors:
          </label>
          {sendingValues.colors && (
            <AntSelect
              mode="multiple"
              showArrow
              tagRender={tagRender}
              onChange={handleChangeColor}
              style={{
                width: '100%',
              }}
              options={colors}
              defaultValue={defaultsColors ? defaultsColors : []}
            />
          )}

          {/* <Select
            className={styles.zIndexColors}
            isMulti
            options={colors}
            onChange={(selectedColors) =>
              setSendingValues((prev) => ({
                ...prev,
                colors: selectedColors,
              }))
            }
            styles={{
              multiValue: (styles, { data }) => ({
                ...styles,
                backgroundColor: data.color,
              }),
              multiValueLabel: (styles, { data }) => ({
                ...styles,
                color: 'white',
              }),
              multiValueRemove: (styles, { data }) => ({
                ...styles,
                color: 'white',
                ':hover': {
                  backgroundColor: data.color,
                  color: 'white',
                },
              }),
              option: (styles, { data, isSelected }) => ({
                ...styles,
                backgroundColor: isSelected ? data.color : 'white',
                color: isSelected ? 'white' : 'black',
              }),
            }}
          /> */}
        </div>
        <div className={styles.productSizeContainer}>
          <label className={styles.defaultSizeLabel}>Default Size:</label>
          <div className={styles.sizeHeight}>
            <label className={styles.sizeLabel}>
              <MdOutlineHeight className={styles.heightIcon} />
              Height:
            </label>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
              }}
              noValidate
              autoComplete="off">
              <TextField
                id="standard-textarea"
                label="Height"
                value={!sendingValues.height ? '' : sendingValues.height}
                onChange={(e) =>
                  setSendingValues((prev) => ({
                    ...prev,
                    height: e.target.value,
                  }))
                }
                placeholder="Enter default height"
                multiline
                variant="standard"
              />
            </Box>
          </div>
          <div className={styles.sizeHeight}>
            <label className={styles.sizeLabel}>
              <RxWidth className={styles.heightIcon} />
              Width:
            </label>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
              }}
              noValidate
              autoComplete="off">
              <TextField
                value={!sendingValues.width ? '' : sendingValues.width}
                onChange={(e) =>
                  setSendingValues((prev) => ({
                    ...prev,
                    width: e.target.value,
                  }))
                }
                id="standard-textarea"
                label="Width"
                placeholder="Enter default width"
                multiline
                variant="standard"
              />
            </Box>
          </div>
          <div className={styles.sizeHeight}>
            <label className={styles.sizeLabel}>
              <RxWidth className={styles.heightIcon} />
              Depth:
            </label>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
              }}
              noValidate
              autoComplete="off">
              <TextField
                value={!sendingValues.depth ? '' : sendingValues.depth}
                onChange={(e) =>
                  setSendingValues((prev) => ({
                    ...prev,
                    depth: e.target.value,
                  }))
                }
                id="standard-textarea"
                label="Depth"
                placeholder="Enter default depth"
                multiline
                variant="standard"
              />
            </Box>
          </div>
        </div>
        <div className={styles.categoriesContainer}>
          <label className={styles.categoryLabel}>Material:</label>
          {sendingValues.material && (
            <AntSelect
              mode="multiple"
              showArrow
              tagRender={tagRenderMaterial}
              onChange={handleChangeMaterial}
              style={{
                width: '100%',
              }}
              options={materials}
              defaultValue={defaultMaterials}
            />
          )}
        </div>
        <div className={styles.editorContainer}>
          <label className={styles.descriptionLabel}>
            <DescriptionIcon />
            Description:
          </label>
          <Editor
            apiKey="d7xcd9eidbmlnfln7xx4mx4ky8iwh2ldpnl5n94hn6myah3u"
            value={sendingValues.description && sendingValues.description}
            onEditorChange={handleEditorChange}
            init={{
              height: 500,
              plugins: 'link image code',
              toolbar:
                'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | link image | code',
              automatic_uploads: true,
              file_picker_types: 'image',
              file_picker_callback: handleFilePicker,
            }}
          />
        </div>
        <form>
          <div className={styles.imgUploaderContainer}>
            <div className={styles.lableImgContainer}>
              <label className={styles.imgLabel}>
                <AiFillPicture className={styles.imgIcon} />
                Images:
              </label>
            </div>
            <div className={styles.imgWrapper}>
              <input
                onChange={onInputChange}
                type="file"
                id="file"
                accept="image/*"
                hidden
                multiple
              />
              <div className={styles.imgArea} data-img="">
                <CloudUploadIcon className={styles.uploadImgIcon} />
                <h3>Upload Image</h3>
                <button type="button" onClick={handleSubmit}>
                  Send
                </button>
              </div>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  document.querySelector('#file').click()
                }}
                className={styles.selectImage}>
                Select Image
              </button>
            </div>
          </div>
        </form>
        {sendingValues.imgs && sendingValues?.imgs?.length ? (
          <div className={styles.imgContainer}>
            {sendingValues.imgs.map((img) => (
              <div key={uuidv4()} className={styles.imgWrapper}>
                <img
                  className={styles.img}
                  src={webpSupported ? img?.webp : img?.jpeg}
                  alt="Image"
                />
                <div
                  className={styles.deleteBtn}
                  onClick={() => handleDeleteImage(img)}></div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
      {/* </div> */}
      <ToastContainer />
    </section>
  )
}
