import styles from './WeAre.module.css'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { useRef, useEffect, useState } from 'react'
import Description from '../../pages/ProductPage/Description/Description'
import { setAboutUs } from '../../store/sections/sectionsAction'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'

const leftAnimation = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.1 },
  }),
}
const rightAnimation = {
  hidden: {
    x: 100,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
  },
}
const bottomAnimation = {
  hidden: {
    y: 100,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
  },
}

export default function WeAre() {
  const [webpSupported, setWebpSupported] = useState(false)
  const selectRef = useRef(null)
  const lang = useSelector((state) => state.lang.lang)
  const dispatch = useDispatch()
  const [about, setAbout] = useState([])
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const isSmallScreen = screenWidth < 1175

  const handleResize = () => {
    setScreenWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const fetchAboutUs = async () => {
    try {
      const res = await axios.get(
        'https://elwood-2e61a87e55cb.herokuapp.com/about'
      )
      const filteredData =
        res.data &&
        res?.data
          .filter((item) => item.lang === lang.toLowerCase())
          .map((item) => {
            return {
              id: item.id,
              lang: item.lang,
              img: JSON.parse(item.img),
              description: item.description,
            }
          })
      filteredData && setAbout(filteredData)
    } catch (error) {
      console.log(error)
    }
  }

  const { t, i18n } = useTranslation()

  useEffect(() => {
    fetchAboutUs()
  }, [lang])

  useEffect(() => {
    setTimeout(() => {
      const aboutValue = selectRef?.current?.offsetTop

      localStorage.setItem('aboutUs', aboutValue)
    }, 1000)
  }, [selectRef?.current?.offsetTop])

  useEffect(() => {
    if (selectRef?.current) {
      dispatch(setAboutUs(selectRef))
    }
  }, [dispatch])

  useEffect(() => {
    const canvas = document.createElement('canvas')
    if (canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0) {
      setWebpSupported(true)
    } else {
      setWebpSupported(false)
    }
  }, [])

  return (
    <motion.section
      viewport={{ amount: 0.2 }}
      initial="hidden"
      whileInView="visible"
      ref={selectRef}
      className={styles.section}>
      <div className={`${styles.container} ${isSmallScreen ? 'swapped' : ''}`}>
        {about &&
          about.map((item, index) => (
            <>
              {isSmallScreen ? (
                <>
                  <div key={item.id} className={styles.left}>
                    <div className={styles.leftContent}>
                      <div className={styles.textContent}>
                        <motion.h1
                          custom={index + 1}
                          variants={leftAnimation}
                          className={styles.title}>
                          {t('weAre')}
                        </motion.h1>
                        <motion.h3
                          custom={index + 2}
                          variants={leftAnimation}
                          className={styles.desc}>
                          <Description desc={item.description} />
                        </motion.h3>
                      </div>
                      <motion.div
                        variants={rightAnimation}
                        className={styles.right}>
                        <img
                          src={
                            webpSupported ? item?.img?.webp : item?.img?.jpeg
                          }
                          alt="ABOUT IMG"
                          className={styles.img}
                        />
                      </motion.div>
                    </div>
                  </div>
                  <motion.div
                    variants={bottomAnimation}
                    className={styles.btnContainer}>
                    <a
                      href="https://www.instagram.com/elwoodfurniture/"
                      target="_blank">
                      <button className={styles.btn}>{t('joinUs')}</button>
                    </a>{' '}
                  </motion.div>
                </>
              ) : (
                <>
                  <div key={item.id} className={styles.left}>
                    <div className={styles.leftContent}>
                      <div className={styles.textContent}>
                        <motion.h1
                          custom={index + 1}
                          variants={leftAnimation}
                          className={styles.title}>
                          {t('weAre')}
                        </motion.h1>
                        <motion.h3
                          custom={index + 2}
                          variants={leftAnimation}
                          className={styles.desc}>
                          <Description desc={item.description} />
                        </motion.h3>
                      </div>
                      <motion.div
                        variants={bottomAnimation}
                        className={styles.btnContainer}>
                        <a
                          href="https://www.instagram.com/elwoodfurniture/"
                          target="_blank">
                          <button className={styles.btn}>{t('joinUs')}</button>
                        </a>
                      </motion.div>
                    </div>
                  </div>
                  <motion.div
                    variants={rightAnimation}
                    className={styles.right}>
                    <img
                      src={webpSupported ? item?.img?.webp : item?.img?.jpeg}
                      alt="ABOUT IMG"
                      className={styles.img}
                    />
                  </motion.div>
                </>
              )}
            </>
          ))}
      </div>
    </motion.section>
  )
}
