import styles from './AddProductPage.module.css'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi'
import { MdAddCircle, MdColorLens, MdOutlineHeight } from 'react-icons/md'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { TbFlag3Filled } from 'react-icons/tb'
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import WhatshotIcon from '@mui/icons-material/Whatshot'
import NewspaperIcon from '@mui/icons-material/Newspaper'
import { IoIosPricetag } from 'react-icons/io'
import Autocomplete from '@mui/material/Autocomplete'
import { useEffect, useState } from 'react'
import axios from 'axios'
import Stack from '@mui/material/Stack'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import Typography from '@mui/material/Typography'
import SendIcon from '@mui/icons-material/Send'
import Modal from '@mui/material/Modal'
import { RxWidth } from 'react-icons/rx'
import { Editor } from '@tinymce/tinymce-react'
import DescriptionIcon from '@mui/icons-material/Description'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { v4 as uuidv4 } from 'uuid'
import { AiFillPicture } from 'react-icons/ai'
import Switch from '@mui/material/Switch'
import { GiSightDisabled } from 'react-icons/gi'
import RefreshIcon from '@mui/icons-material/Refresh'
import dayjs from 'dayjs'
import { TbRefreshDot } from 'react-icons/tb'
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage'
import storage from '../../firebase'
import { ToastContainer, toast } from 'react-toastify'
import imageCompression from 'browser-image-compression'
import { Select as AntSelect, Tag } from 'antd'
import Loader from '../../components/Loader/Loader'
import Button from '@mui/material/Button'
import { BarLoader } from 'react-spinners'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: -10,
  p: 4,
}

const tagRender = (props) => {
  const { label, value, closable, onClose } = props
  const onPreventMouseDown = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  return (
    <Tag
      color={value}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
      }}>
      {label}
    </Tag>
  )
}

const tagRenderMaterial = (props) => {
  const { label, value, closable, onClose } = props
  const onPreventMouseDown = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }
  return (
    <Tag
      color={value}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
        color: 'black',
        background: '#ccc',
      }}>
      {label}
    </Tag>
  )
}
const tagRenderCategory = (props) => {
  const { label, value, closable, onClose } = props
  const onPreventMouseDown = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }
  return (
    <Tag
      color={value}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
        color: 'black',
        background: '#ccc',
      }}>
      {label}
    </Tag>
  )
}

export default function AddProductPage() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState(null)
  const [content, setContent] = useState('')
  const [open, setOpen] = useState('')
  const [sendCategoryLoading, setSendCategoryLoading] = useState(false)
  const [sendMaterialLoading, setSendMaterialLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [materials, setMaterials] = useState([])
  const [webpSupported, setWebpSupported] = useState(false)
  const [colors, setColors] = useState([])
  const [imgLoader, setImgLoader] = useState(false)
  const [addCategory, setAddCategory] = useState({
    category: '',
    disabled: 0,
    lang: '',
  })
  const [addMaterial, setAddMaterial] = useState({
    material: '',
    disabled: 0,
    lang: '',
  })
  const [sendingValues, setSendingValues] = useState({
    name: '',
    status: 0,
    price: 0,
    category: [],
    material: [],
    colors: [],
    imgs: [],
    description: '',
    height: 0,
    width: 0,
    depth: 0,
    disabled: 0,
    oldPrice: 0,
    date: dayjs().format('DD.MM.YYYY'),
  })
  const [test, setTest] = useState([])
  const [materialTest, setMaterialTest] = useState([])
  const [colorTest, setColorTest] = useState([])
  const label = { inputProps: { 'aria-label': 'Switch demo' } }

  const handleDeleteImage = (img) => {
    const imageRefJpeg = ref(storage, img.jpeg)
    const imageRefWebp = ref(storage, img.webp)

    deleteObject(imageRefJpeg).then(() =>
      console.log('Jpeg has been delete sucess')
    )
    deleteObject(imageRefWebp).then(() =>
      console.log('Webp has been elete success')
    )

    setSendingValues((prev) => ({
      ...prev,
      imgs: [
        ...sendingValues.imgs.filter((filteredImg) => filteredImg !== img),
      ],
    }))
  }

  const handleEditorChange = (content, editor) => {
    setContent(content)
  }

  console.log('ADD PRODUCT CATE MAT')
  console.log(options)
  console.log(materials)

  const handleSendValues = async (e) => {
    setLoading(true)
    let categoriesArray = []
    let materialsArray = []
    let colorsArray = []
    if (Array.isArray(test) && test.length > 0) {
      const res = await axios.get(
        'https://elwood-2e61a87e55cb.herokuapp.com/categories'
      )
      const filteredDatas =
        res.data.length &&
        res.data
          .filter((item) => !item.disabled)
          .filter((item) => test.includes(item.category))

      categoriesArray = filteredDatas
    }

    if (Array.isArray(materialTest) && materialTest.length > 0) {
      const res = await axios.get(
        'https://elwood-2e61a87e55cb.herokuapp.com/materials'
      )
      const filteredDatas =
        res.data.length &&
        res.data
          .filter((item) => !item.disabled)
          .filter((item) => materialTest.includes(item.material))

      materialsArray = filteredDatas
    }
    if (Array.isArray(colorTest) && colorTest.length > 0) {
      const res = await axios.get(
        'https://elwood-2e61a87e55cb.herokuapp.com/colors'
      )
      const filteredDatas =
        res.data.length &&
        res.data
          .filter((item) => !item.disabled)
          .filter((item) => colorTest.includes(item.color))
          .map((item) => {
            return { color: item.color, label: item.color, value: item.color }
          })

      colorsArray = filteredDatas
    }

    try {
      await axios
        .post(
          'https://elwood-2e61a87e55cb.herokuapp.com/addProductFurniture/arm',
          {
            ...sendingValues,
            material: JSON.stringify(materialsArray),
            category: JSON.stringify(categoriesArray),
            imgs: JSON.stringify(sendingValues.imgs),
            colors: JSON.stringify(colorsArray),
          }
        )
        .then((res) => {
          console.log('res.,data')
          console.log(res)
          setTimeout(() => {
            setLoading(false)
            toast.success('Product has been created successfully !')
            navigate('/admin/admindashboard/products')
          }, 3000)
        })
        .catch((err) => console.log(err))
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    const container = document.createElement('p')

    container.innerHTML = content

    const descriptionElement = container.querySelector('p')
    const description = descriptionElement && descriptionElement.outerHTML

    setSendingValues((prev) => ({ ...prev, description: description }))
  }, [content])

  const onInputChange = (e) => {
    setFiles(e.target.files)
  }

  async function uploadImgProduct() {
    let newFileNames = []
    let downloadURLs = []

    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      const newFileName = uuidv4() + '.' + file.name.split('.').pop()
      newFileNames.push(newFileName)
      setImgLoader(true)

      const jpegImageRef = ref(
        storage,
        `images/testProducts/${newFileName}.jpeg`
      )
      const webpImageRef = ref(
        storage,
        `images/testProducts/${newFileName}.webp`
      )

      try {
        const compressedImage = await imageCompression(file, {
          maxSize: 500 * 1024,
          maxWidthOrHeight: 800,
        })

        const jpegCompressedImage = await imageCompression(compressedImage, {
          fileType: 'jpeg',
        })

        const webpCompressedImage = await imageCompression(compressedImage, {
          fileType: 'webp',
        })

        await uploadBytes(jpegImageRef, jpegCompressedImage)
        await uploadBytes(webpImageRef, webpCompressedImage).then(() => {
          toast.success('Image uploaded success !')
        })

        const jpegDownloadURL = await getDownloadURL(jpegImageRef)
        const webpDownloadURL = await getDownloadURL(webpImageRef)

        const imgObj = { jpeg: jpegDownloadURL, webp: webpDownloadURL }
        downloadURLs.push(imgObj)
      } catch (error) {
        toast.error(error.message)
      }
    }

    setSendingValues((prev) => ({
      ...prev,
      imgs: [...prev.imgs, ...downloadURLs],
    }))
    setImgLoader(false)
  }

  function handleSubmit(e) {
    e.preventDefault()
    uploadImgProduct()
  }

  const handleInputChange = (e) => {
    setAddCategory((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await axios.get(
          'https://elwood-2e61a87e55cb.herokuapp.com/categories'
        )

        const filteredDatas =
          res.data.length &&
          res.data
            .filter(
              (item) => !item.disabled && item.lang.toLowerCase() === 'arm'
            )
            .map((item) => {
              return { value: item.category }
            })

        setOptions(filteredDatas ? filteredDatas : [])
      } catch (error) {
        toast.error(error.message)
      }
    }
    fetchCategories()
  }, [])

  useEffect(() => {
    const fetchMaterials = async () => {
      try {
        const res = await axios.get(
          'https://elwood-2e61a87e55cb.herokuapp.com/materials'
        )

        const filteredDatas =
          res.data.length &&
          res.data
            .filter(
              (item) => !item.disabled && item.lang.toLowerCase() === 'arm'
            )
            .map((item) => {
              return {
                value: item.material,
              }
            })

        setMaterials(filteredDatas ? filteredDatas : [])
      } catch (error) {
        toast.error(error.message)
      }
    }
    fetchMaterials()
  }, [])

  useEffect(() => {
    const fetchColors = async () => {
      try {
        const res = await axios.get(
          'https://elwood-2e61a87e55cb.herokuapp.com/colors'
        )
        const filteredDatas =
          res.data.length &&
          res.data
            .filter((item) => !item.disabled)
            .map((item) => {
              return {
                value: item.color,
              }
            })
        setColors(filteredDatas ? filteredDatas : [])
        // if (filteredDatas.length > 0 && Array.isArray(filteredDatas)) {
        //   filteredDatas?.map((item) =>
        //     setColors((prev) => [
        //       ...prev,
        //       {
        //         label: item.color,
        //         value: item.color.toLowerCase(),
        //         color: item.color,
        //       },
        //     ])
        //   )
        // }
      } catch (error) {
        toast.error(error.message)
      }
    }
    fetchColors()
  }, [])

  const defaultProps = {
    options: options,
    getOptionLabel: (option) => option.category,
    key: uuidv4(),
  }

  const handleOpenCategory = () => setOpen('addCategory')
  const handleCloseCategory = () => {
    setOpen('')
    setAddCategory({ category: '', disabled: 0 })
  }
  const handleOpenMaterial = () => setOpen('addMaterial')
  const handleCloseMaterial = () => {
    setOpen('')
    setAddMaterial({ material: '', disabled: 0 })
  }

  async function handleSend(e) {
    e.preventDefault()
    setSendCategoryLoading(true)

    try {
      await axios
        .post(
          'https://elwood-2e61a87e55cb.herokuapp.com/addcategory',
          addCategory
        )
        .then(() => {
          setOpen('')
          setSendCategoryLoading(false)
          setAddCategory({ category: '', disabled: 0 })
          toast.success('Category has been created successfully !')
        })
      const updatedCategories = await axios.get(
        'https://elwood-2e61a87e55cb.herokuapp.com/categories'
      )
      const filteredDatas =
        updatedCategories.data.length &&
        updatedCategories.data
          .filter((item) => !item.disabled)
          .map((item) => {
            return { value: item.category }
          })
      setOptions(filteredDatas)
    } catch (error) {
      toast.error(error.message)
    }
  }
  const handleSwitchChange = (e) => {
    setSendingValues((prev) => ({
      ...prev,
      disabled: prev.disabled ? 0 : 1,
    }))
  }

  async function handleSendMaterial(e) {
    e.preventDefault()
    setSendMaterialLoading(true)
    try {
      await axios
        .post(
          'https://elwood-2e61a87e55cb.herokuapp.com/addmaterial',
          addMaterial
        )
        .then(() => {
          setOpen('')
          setSendMaterialLoading(false)
          setAddMaterial({ material: '', disabled: 0 })
          toast.success('Material has been created successfully !')
        })
      const updatedMaterials = await axios.get(
        'https://elwood-2e61a87e55cb.herokuapp.com/materials'
      )
      const filteredDatas =
        updatedMaterials.data.length &&
        updatedMaterials.data
          .filter((item) => !item.disabled)
          .map((item) => {
            return {
              value: item.material,
            }
          })
      setMaterials(filteredDatas)
    } catch (error) {
      toast.error(error.message)
    }
  }

  function handleMaterialChange(e) {
    setAddMaterial((prev) => ({ ...prev, material: e.target.value }))
  }
  function handleMaterialChangeLang(e) {
    setAddMaterial((prev) => ({ ...prev, lang: e.target.value }))
  }

  const handleFilePicker = (callback, value, meta) => {
    if (meta.filetype === 'image') {
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/jpeg')
      input.onchange = function () {
        const file = this.files[0]
        callback(`/image/main/${file.name}`)
      }
      input.click()
    }
  }

  useEffect(() => {
    const canvas = document.createElement('canvas')
    if (canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0) {
      setWebpSupported(true)
    } else {
      setWebpSupported(false)
    }
  }, [])

  const handleSelectChange = (selectedValues) => {
    const colorsArray = selectedValues.map((color) => ({
      label: color,
      value: color,
      color: color,
    }))

    setSendingValues((prev) => ({
      ...prev,
      colors: colorsArray,
    }))
  }

  const handleChangeMaterial = (selectedValues) => {
    setMaterialTest([...selectedValues])
  }
  const handleChangeCategory = (selectedValues) => {
    setTest([...selectedValues])
  }

  const handleChangeColors = (selectedValues) => {
    setColorTest([...selectedValues])
  }

  return (
    <section className={styles.section}>
      <div className={styles.containerHeader}>
        <header className={styles.header}>
          <div className={styles.leftHeader}>
            <h1>Creating Product</h1>
          </div>
          <div className={styles.rightHeader}>
            <div className={styles.backContainer}>
              <Breadcrumbs aria-label="breadcrumb">
                <RouterLink to="/admin/admindashboard/products">
                  <div className={styles.backContainer}>
                    <HiOutlineArrowNarrowLeft className={styles.backIcon} />
                    Go Back
                  </div>
                </RouterLink>
                <button onClick={handleSendValues} className={styles.btn}>
                  <MdAddCircle
                    onClick={handleSendValues}
                    className={styles.iconBtn}
                  />
                  Create
                </button>
              </Breadcrumbs>
            </div>
          </div>
        </header>
      </div>
      <div className={styles.container}>
        <div className={styles.disabledContainer}>
          <label className={styles.disabledLabel}>
            <GiSightDisabled className={styles.disabledIcon} />
            Disabled:
          </label>
          <Switch
            {...label}
            checked={sendingValues.disabled === 1}
            onChange={handleSwitchChange}
          />
        </div>
        <div className={styles.formNameContainer}>
          <label className={styles.labelName}>
            <TbFlag3Filled className={styles.productNameIcon} />
            Product Name:
          </label>
          <Box
            sx={{
              width: 300,
              maxWidth: '100%',
            }}>
            <TextField
              label="Name"
              value={sendingValues.name}
              onChange={(e) =>
                setSendingValues((prev) => ({
                  ...prev,
                  name: e.target.value,
                }))
              }
              id="outlined-size-small"
              size="small"
              fullWidth
            />
          </Box>
        </div>
        <div className={styles.statusContainer}>
          <label className={styles.labelStatus}>
            <MilitaryTechIcon />
            Status:
          </label>
          <div className={styles.statusWrapperContainer}>
            <div
              className={`${styles.status_es} ${
                sendingValues.status === 1 && styles.statusRef
              }`}>
              <Box sx={{ width: 100 }}>
                <Grid
                  container
                  justifyContent="center"
                  className={styles.statusIconContainer}>
                  <Tooltip title="Hit Status & Home Status" placement="top">
                    <WhatshotIcon
                      onClick={() =>
                        setSendingValues((prev) => ({ ...prev, status: 1 }))
                      }
                      className={styles.statusIcon}
                    />
                  </Tooltip>
                  <span
                    onClick={() =>
                      setSendingValues((prev) => ({ ...prev, status: 1 }))
                    }>
                    Hite
                  </span>
                </Grid>
              </Box>
            </div>
            <div
              className={`${styles.status_es} ${
                sendingValues.status === 2 && styles.statusRef
              }`}>
              <Box sx={{ width: 100 }}>
                <Grid
                  container
                  justifyContent="center"
                  className={styles.statusIconContainer}>
                  <Tooltip title="Sale Items Status" placement="top">
                    <NewspaperIcon
                      onClick={() =>
                        setSendingValues((prev) => ({ ...prev, status: 2 }))
                      }
                      className={styles.statusIcon}
                    />
                  </Tooltip>
                  <span
                    onClick={() =>
                      setSendingValues((prev) => ({ ...prev, status: 2 }))
                    }>
                    News
                  </span>
                </Grid>
              </Box>
            </div>
          </div>
          <div
            className={`${styles.status_es} ${
              sendingValues.status === 0 && styles.statusRef
            }`}>
            <Box sx={{ width: 100 }}>
              <Grid
                container
                justifyContent="center"
                className={`${styles.statusIconContainer} ${styles.refreshContainer}`}>
                <Tooltip title="Reset" placement="top">
                  <RefreshIcon
                    onClick={() =>
                      setSendingValues((prev) => ({ ...prev, status: 0 }))
                    }
                    className={`${styles.statusIcon} ${styles.restart}`}
                  />
                </Tooltip>
                <span
                  onClick={() =>
                    setSendingValues((prev) => ({ ...prev, status: 0 }))
                  }>
                  Reset
                </span>
              </Grid>
            </Box>
          </div>
        </div>

        <div className={styles.priceContainer}>
          <label style={{ marginRight: '20px' }} className={styles.labelPrice}>
            <IoIosPricetag className={styles.priceIcon} />
            Price:
          </label>
          <TextField
            id="standard-textarea"
            label="Price"
            value={sendingValues.price}
            onChange={(e) =>
              setSendingValues((prev) => ({ ...prev, price: e.target.value }))
            }
            placeholder="Enter product price"
            multiline
            variant="standard"
          />{' '}
          <span style={{ marginTop: '13px', marginLeft: '20px' }}>AMD</span>
        </div>
        <div className={styles.priceContainer}>
          <label className={styles.labelOldPrice}>
            <TbRefreshDot className={styles.oldPrice} />
            Old Price. Not necessary
          </label>
          <TextField
            id="standard-textarea"
            label="Old Price"
            value={sendingValues.oldPrice}
            onChange={(e) =>
              setSendingValues((prev) => ({
                ...prev,
                oldPrice: e.target.value,
              }))
            }
            placeholder="Enter old price"
            multiline
            variant="standard"
          />
        </div>
        <div className={styles.categoriesContainer}>
          <label className={styles.categoryLabel}>Category:</label>
          <AntSelect
            mode="multiple"
            showArrow
            tagRender={tagRenderCategory}
            onChange={handleChangeCategory}
            style={{
              width: '100%',
            }}
            options={options}
          />

          {/* <Stack spacing={1} sx={{ width: 300 }}>
            <Autocomplete
              {...defaultProps}
              id="controlled-demo"
              value={sendingValues.category}
              onChange={(event, newValue) => {
                setSendingValues((prev) => ({ ...prev, category: newValue }))
              }}
              renderInput={(params) => (
                <TextField {...params} label="Categories" variant="standard" />
              )}
            />
          </Stack> */}
          <div className={styles.addNewCategory}>
            <button
              onClick={handleOpenCategory}
              className={`${styles.btn} ${styles.addCategoryButton}`}>
              <MdAddCircle className={styles.iconBtn} />
              Create Category
            </button>
          </div>
        </div>
        <div className={styles.categoriesContainer}>
          <label className={styles.categoryLabel}>Material:</label>
          <AntSelect
            mode="multiple"
            showArrow
            tagRender={tagRenderMaterial}
            onChange={handleChangeMaterial}
            style={{
              width: '100%',
            }}
            options={materials}
          />

          <div className={styles.addNewCategory}>
            <button
              className={`${styles.btn} ${styles.addCategoryButton}`}
              onClick={handleOpenMaterial}>
              <MdAddCircle className={styles.iconBtn} />
              Create Material
            </button>
          </div>
        </div>

        <div className={styles.productSizeContainer}>
          <label className={styles.defaultSizeLabel}>Default Size:</label>
          <div className={styles.sizeHeight}>
            <label className={styles.sizeLabel}>
              <MdOutlineHeight className={styles.heightIcon} />
              Height:
            </label>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
              }}
              noValidate
              autoComplete="off">
              <TextField
                id="standard-textarea"
                label="Height"
                value={sendingValues.height}
                onChange={(e) =>
                  setSendingValues((prev) => ({
                    ...prev,
                    height: e.target.value,
                  }))
                }
                placeholder="Enter default height"
                multiline
                variant="standard"
              />
            </Box>
          </div>
          <div className={styles.sizeHeight}>
            <label className={styles.sizeLabel}>
              <RxWidth className={styles.heightIcon} />
              Width:
            </label>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
              }}
              noValidate
              autoComplete="off">
              <TextField
                value={sendingValues.width}
                onChange={(e) =>
                  setSendingValues((prev) => ({
                    ...prev,
                    width: e.target.value,
                  }))
                }
                id="standard-textarea"
                label="Width"
                placeholder="Enter default width"
                multiline
                variant="standard"
              />
            </Box>
          </div>
          <div className={styles.sizeHeight}>
            <label className={styles.sizeLabel}>
              <RxWidth className={styles.heightIcon} />
              Depth:
            </label>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
              }}
              noValidate
              autoComplete="off">
              <TextField
                value={sendingValues.depth}
                onChange={(e) =>
                  setSendingValues((prev) => ({
                    ...prev,
                    depth: e.target.value,
                  }))
                }
                id="standard-textarea"
                label="Depth"
                placeholder="Enter default Depth"
                multiline
                variant="standard"
              />
            </Box>
          </div>
        </div>
        <div className={styles.colorWrapper}>
          <div className={styles.colorsContainer}>
            <label className={styles.colorsLable}>
              <MdColorLens className={styles.colorsIcon} />
              Colors:
            </label>
            <AntSelect
              mode="multiple"
              showArrow
              tagRender={tagRender}
              onChange={handleChangeColors}
              style={{
                width: '100%',
              }}
              options={colors}
            />
            {/* <Select
              className={styles.zIndex}
              isMulti
              options={colors}
              onChange={(selectedColors) =>
                setSendingValues((prev) => ({
                  ...prev,
                  colors: selectedColors,
                }))
              }
              styles={{
                multiValue: (styles, { data }) => ({
                  ...styles,
                  backgroundColor: data.color,
                }),
                multiValueLabel: (styles, { data }) => ({
                  ...styles,
                  color: 'white',
                }),
                multiValueRemove: (styles, { data }) => ({
                  ...styles,
                  color: 'white',
                  ':hover': {
                    backgroundColor: data.color,
                    color: 'white',
                  },
                }),
                option: (styles, { data, isSelected }) => ({
                  ...styles,
                  backgroundColor: isSelected ? data.color : 'white',
                  color: isSelected ? 'white' : 'black',
                }),
              }}
            /> */}
          </div>
        </div>
        <div className={styles.editorContainer}>
          <label className={styles.descriptionLabel}>
            <DescriptionIcon />
            Description:
          </label>
          <Editor
            className={styles.editor}
            apiKey="d7xcd9eidbmlnfln7xx4mx4ky8iwh2ldpnl5n94hn6myah3u"
            initialValue="<p>Enter your product description...</p>"
            init={{
              height: 500,
              plugins: 'link image code',
              toolbar:
                'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | link image | code',
              automatic_uploads: true,
              file_picker_types: 'image',
              file_picker_callback: handleFilePicker,
            }}
            onEditorChange={handleEditorChange}
          />
        </div>
        <form>
          <div className={styles.imgUploaderContainer}>
            <div className={styles.lableImgContainer}>
              <label className={styles.imgLabel}>
                <AiFillPicture className={styles.imgIcon} />
                Images:
              </label>
            </div>
            <div className={styles.imgWrapper}>
              <input
                onChange={onInputChange}
                type="file"
                id="file"
                accept="image/*"
                hidden
                multiple
              />
              <div className={styles.imgArea} data-img="">
                <CloudUploadIcon className={styles.uploadImgIcon} />
                <h3 className={styles.uploadImageTitle}>Upload Image</h3>
                <Stack spacing={2} direction="row">
                  <Button onClick={handleSubmit} variant="outlined">
                    Send Img
                  </Button>
                </Stack>
              </div>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  document.querySelector('#file').click()
                }}
                className={styles.selectImage}>
                Select Image
              </button>
            </div>
          </div>
          {imgLoader && <BarLoader width="100%" color="rgb(219, 128, 48)" />}
        </form>
        {sendingValues.imgs.length ? (
          <div className={styles.imgContainer}>
            {sendingValues.imgs.map((img) => (
              <div key={uuidv4()} className={styles.imgWrapper}>
                <img
                  className={styles.img}
                  src={webpSupported ? img?.webp : img?.jpeg}
                  alt="Product"
                />
                <div
                  className={styles.deleteBtn}
                  onClick={() => handleDeleteImage(img)}></div>
              </div>
            ))}
          </div>
        ) : null}

        <Stack spacing={2} direction="row">
          <Modal
            className={styles.modalZIndex}
            open={open === 'addCategory'}
            onClose={handleCloseCategory}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className={styles.titleModal}>
                Create New Category
              </Typography>
              <Box
                className={styles.addInpCateogoryContainer}
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off">
                <TextField
                  id="standard-basic"
                  label="Add Category"
                  value={addCategory.category}
                  variant="standard"
                  color="primary"
                  name="category"
                  onChange={handleInputChange}
                />
              </Box>
              <Box
                className={styles.addInpCateogoryContainer}
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off">
                <TextField
                  id="standard-basic"
                  label="Language Variant"
                  value={addCategory.lang}
                  variant="standard"
                  color="primary"
                  name="lang"
                  onChange={handleInputChange}
                />
              </Box>
              <Box className={styles.sendButtonContainer}>
                <Box sx={{ '& > button': { m: 1 } }}>
                  <LoadingButton
                    onClick={handleSend}
                    endIcon={<SendIcon />}
                    loading={sendCategoryLoading}
                    loadingPosition="end"
                    variant="contained">
                    <span>Send</span>
                  </LoadingButton>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Stack>
        <Stack spacing={2} direction="row">
          <Modal
            className={styles.modalZIndex}
            open={open === 'addMaterial'}
            onClose={handleCloseMaterial}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className={styles.titleModal}>
                Create New Material
              </Typography>
              <Box
                className={styles.addInpCateogoryContainer}
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off">
                <TextField
                  id="standard-basic"
                  label="Add Material"
                  value={addMaterial.material}
                  variant="standard"
                  color="primary"
                  name="category"
                  onChange={handleMaterialChange}
                />
              </Box>
              <Box
                className={styles.addInpCateogoryContainer}
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off">
                <TextField
                  id="standard-basic"
                  label="Language Variant"
                  value={addMaterial.lang}
                  variant="standard"
                  color="primary"
                  name="lang"
                  onChange={handleMaterialChangeLang}
                />
              </Box>

              <Box className={styles.sendButtonContainer}>
                <Box sx={{ '& > button': { m: 1 } }}>
                  <LoadingButton
                    onClick={handleSendMaterial}
                    endIcon={<SendIcon />}
                    loading={sendCategoryLoading}
                    loadingPosition="end"
                    variant="contained">
                    <span>Send</span>
                  </LoadingButton>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Stack>
      </div>
      <ToastContainer />
      {loading && <Loader />}
    </section>
  )
}
